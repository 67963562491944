import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, Link } from "react-router-dom"

import Page from '../components/layouts/Page'
import TextInput from '../components/inputs/Text'
import Validator from '../lib/Validator'
import { setProductions, setFlash } from '../features/user/homeSlice'
import { Button, Row, Col, Form } from 'react-bootstrap'
import { requestNewDevice, requestNewHandset } from '../lib/Api'
import { useTitle } from '../lib/Utils'


function NewDevice(props) {
    useTitle('new Device')
    const dispatch = useDispatch()
    const history = useHistory()

    const production_id = props.match.params.production_id

    const [values, setValues] = useState({
        name: ''
    })
    const [errors, setErrors] = useState({})

    const user = useSelector((state) => state.home.user)
    const validation_object = 'device'

    function handleChange(name, value) {
        const newValues = { ...values, [name]: value }
        setValues(newValues)
        setErrors(Validator(validation_object, newValues))
    }

    function submitForm(e) {
        e.preventDefault()
        const errors = Validator(validation_object, values)
        if (Object.keys(errors).length) return false

        values.production_id = production_id
        values.config = {
            "date": "2023-03-02T03:31:13.613Z",
            "theme": {
                "color": "rgba(204,204,204,1)",
                "uiScale": 1,
                "highlight": "rgba(100,155,250,1)",
                "background": "rgba(10,10,10,1)"
            },
            "notes": [],
            "sleepTimeout": 120,
            "broken": false,
            "photos": [
                "https://prop-os-prod.s3.amazonaws.com/production_uploads/4323089492.jpg",
                "https://prop-os-prod.s3.amazonaws.com/production_uploads/9271717352.jpg",
                "https://prop-os-prod.s3.amazonaws.com/production_uploads/5931329128.jpg",
                "https://prop-os-prod.s3.amazonaws.com/production_uploads/2508086993.jpg"
            ],
            "mail": {
                "Inbox": [
                    {
                        "from": "Bret",
                        "to": "weyland@yutani.com",
                        "time": "12.30pm 12 July",
                        "subject": "Dude, call me!",
                        "content": "I've discovered something quite incredible - call me!!!",
                        "new": true
                    },
                    {
                        "from": "Zack Mansford",
                        "to": "weyland@yutani.com",
                        "time": "2.14pm 10 July",
                        "subject": "It's time.",
                        "content": "There are bigger things, greater skies than these. It is time to depart.",
                        "new": false
                    }
                ],
                "Drafts": [],
                "Sent": [],
                "Junk": [],
                "Trash": []
            },
            "lockType": "none",
            "lockPin": 1234,
            "contacts": [
                {
                    "name": "Martin Shelley",
                    "email": "marty@chicken.com",
                    "image": "https://prop-os-prod.s3.amazonaws.com/production_uploads/6913264198.png",
                    "phone": "1235551234",
                    "address": "9303 Lyon Drive, Lyon Estates, Hill Valley CA 95420"
                }
            ],
            "darkMode": true,
            "dockApps": [
                {
                    "name": "Phone",
                    "badge": 6
                },
                {
                    "name": "Messages",
                    "badge": 30
                },
                {
                    "name": "News",
                    "badge": 0
                },
                {
                    "name": "Calendar",
                    "badge": 0
                }
            ],
            "homeApps": [
                [
                    {
                        "name": "Settings",
                        "badge": 0
                    },
                    {
                        "name": "Mail",
                        "badge": 361
                    },
                    {
                        "name": "Maps",
                        "slug": "maps_01",
                        "badge": 0
                    },
                    {
                        "name": "Browserly",
                        "slug": "browser_01",
                        "badge": 0
                    },
                    {
                        "name": "Photos",
                        "badge": 0
                    },
                    {
                        "name": "Music",
                        "slug": "music_01",
                        "badge": 0
                    },
                    {
                        "name": "Stocks",
                        "badge": 0
                    },
                    {
                        "name": "The Gram",
                        "badge": 0
                    },
                    {
                        "name": "Steinberg",
                        "badge": 0
                    },
                    {
                        "name": "Mindfulness",
                        "slug": "mindfulness_01",
                        "badge": 0
                    },
                    {
                        "name": "Miso",
                        "slug": "miso_01",
                        "badge": 0
                    },
                    {
                        "name": "Books",
                        "slug": "books_01",
                        "badge": 0
                    },
                    {
                        "name": "Health",
                        "badge": 0
                    },
                    {
                        "name": "Finder",
                        "badge": 0
                    },
                    {
                        "name": "Weather",
                        "badge": 0
                    },
                    {
                        "name": "Notes",
                        "badge": 0
                    },
                    {
                        "name": "Slick",
                        "slug": "slick_01",
                        "badge": 0
                    },
                    {
                        "name": "Contacts",
                        "badge": 0
                    }
                ],
                [
                    {
                        "name": "TakTik",
                        "badge": 0
                    },
                    {
                        "name": "Psycho Frog",
                        "badge": 0
                    },
                    {
                        "name": "Podcasts",
                        "badge": 0
                    },
                    {
                        "name": "Fruitfly",
                        "slug": "fruitfly_01",
                        "badge": 0
                    },
                    {
                        "name": "Bomman",
                        "slug": "bomman_01",
                        "badge": 0
                    },
                    {
                        "name": "Candy King",
                        "badge": 0
                    },
                    {
                        "name": "Prider",
                        "slug": "prider_01",
                        "badge": 0
                    },
                    {
                        "name": "Dice",
                        "slug": "dice_01",
                        "badge": 0
                    },
                    {
                        "name": "Temple",
                        "slug": "temple_01",
                        "badge": 0
                    },
                    {
                        "name": "Streamberry",
                        "badge": 0
                    },
                    {
                        "name": "YouMov",
                        "badge": 0
                    },
                    {
                        "name": "aChat",
                        "badge": 0
                    },
                    {
                        "name": "Vid Call",
                        "badge": 0
                    }
                ]
            ],
            "messages": [],
            "cellSignal": 26,
            "wifiSignal": 50,
            "recentCalls": [{
                "name": "John Snowden",
                "time": "2021-02-07T16:25:19.814Z",
                "type": "inbound",
                "image": ""
            }, {
                "name": "Brad Chas",
                "time": "2021-02-07T16:25:12.814Z",
                "type": "outbound",
                "image": ""
            }, {
                "name": "Bret",
                "time": "2021-02-02T16:25:19.814Z",
                "type": "missed",
                "image": ""
            }],
            "batteryLevel": 70,
            "pushNotifications": [
                {
                    "icon": "https://www.prop-os.com/apps/phone.png",
                    "text": "from Mum",
                    "when": "now",
                    "title": "Missed Call",
                    "appName": "phone"
                },
            ],
            "homescreenWallpaper": "https://prop-os-prod.s3.amazonaws.com/production_uploads/5243184893.png",
            "lockscreenWallpaper": "https://prop-os-prod.s3.amazonaws.com/production_uploads/8190572251.png",
            "showBatteryPercentage": false,
            "browser": {
                "asimov.com": {
                    "results": [
                        {
                            "title": "William Shatner",
                            "website": "Wikipedae",
                            "content": "William Shatner is a Canadian American actor",
                            "link": "wikipedae.com/william-shatner"
                        },
                        {
                            "title": "William Shakespere",
                            "website": "Wikipedae",
                            "content": "William Shakespere is a disappointing writer from the actual past",
                            "link": "wikipedae.com/william-shakespere"
                        },
                        {
                            "title": "Willie Nelson",
                            "website": "PNN",
                            "content": "Willie Nelson Cancels current tour",
                            "link": "pnn.com/nelson-cancels-tour"
                        }
                    ]
                }
            }
        }



        const request = requestNewDevice(values)
        request.then((response) => {
            const prod = response.data.filter(prod => { return prod.external_id === production_id })[0]
            addHandset(prod.devices[prod.devices.length - 1], production_id)
        }).catch((error) => {
            dispatch(setFlash('Failed'))
        })

        return false
    }

    const addHandset = (device, production_id) => {
        const request = requestNewHandset({ device_id: device.external_id })
        request.then((response) => {
            dispatch(setProductions(response.data))
            history.push('/production/' + production_id)
        }).catch((error) => {
            dispatch(setFlash("Couldn't Create Handset"))
        })
    }

    let isError = Object.keys(Validator(validation_object, values)).length > 0

    if (!user.token)
        return <Page><Link to="/login">Login Required</Link></Page>

    return (
        <Page>
            <Row>
                <Col md={{ span: 6, offset: 3 }} className="text-center">
                    <h2>Create a Device</h2>
                </Col>
            </Row>
            <Row className='mtop-30'>
                <Col md={{ span: 6, offset: 3 }}>
                    <Form onSubmit={submitForm}>
                        <Row>
                            <Col md={{ span: 12 }}>
                                <TextInput
                                    name="name"
                                    title="Name"
                                    onUpdate={handleChange}
                                    placeholder="Marty's phone"
                                    errors={errors}
                                    required={true}
                                    inputType='text'
                                />
                            </Col>
                        </Row>

                        <div className="d-grid gap-2 mtop-30">
                            <Button variant="primary" type="submit" disabled={isError}>
                                Submit
                            </Button>
                        </div>

                    </Form>
                </Col>
            </Row>
        </Page>
    )
}

export default NewDevice
