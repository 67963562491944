import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Form } from 'react-bootstrap'
import TextInput from '../components/inputs/Text'
import { formatDatetime } from '../lib/Utils'
import Cookies from 'js-cookie'

import { requestUpload, requestDeleteUpload, requestUpdateUpload } from '../lib/Api'
import { setProductions, setFlash } from '../features/user/homeSlice'

function Uploads(props) {
    const dispatch = useDispatch()
    const { production } = props
    const [inProgress, setInProgress] = useState(false)
    const [view, setView] = useState(getView())
    const [orderBy, setOrderBy] = useState('created_at')
    const [orderDir, setOrderDir] = useState('desc')

    function getView() {
        const cookie = Cookies.get('uploadView')
        if (cookie) return cookie
        Cookies.set('uploadView', 'icon', { expires: 90000 })
        return 'icon'
    }

    function _setView(type) {
        setView(type)
        Cookies.set('uploadView', type, { expires: 90000 })
    }

    const uploadFile = (e) => {
        setInProgress(e.target.files[0].name)
        const request = requestUpload(e.target.files[0], production.external_id)
        request.then((response) => {
            dispatch(setProductions(response.data))
            dispatch(setFlash(`File Uploaded`))
            setInProgress(false)
        }).catch((error) => {
            dispatch(setFlash('Failed'))
            setInProgress(false)
        })
    }

    const sortedUploads = () => {
        if (orderDir === 'asc') {
            return [...production.uploads].sort((a, b) => (a[orderBy] > b[orderBy]) ? 1 : ((b[orderBy] > a[orderBy]) ? -1 : 0))
        }
        return [...production.uploads].sort((a, b) => (a[orderBy] < b[orderBy]) ? 1 : ((b[orderBy] < a[orderBy]) ? -1 : 0))
    }

    const delPhoto = (external_id) => {
        if (!window.confirm('Are you sure you want to delete? This cannot be undone.')) return
        const request = requestDeleteUpload(external_id)
        request.then((response) => {
            dispatch(setProductions(response.data))
            dispatch(setFlash(`Upload Deleted`))
        }).catch((error) => {
            dispatch(setFlash('Failed'))
        })
    }

    const toggleView = () => {
        if (view === 'icon') { return _setView('list') }
        _setView('icon')
    }

    const toggleSort = () => {
        if (orderDir === 'asc') { return setOrderDir('desc') }
        if (orderBy === 'created_at') { setOrderBy('name'); setOrderDir('asc'); return }
        if (orderBy === 'name') { setOrderBy('created_at'); setOrderDir('asc'); return }
    }

    function handleNameUpdate(external_id, value) {
        if (value.length < 2) return dispatch(setFlash('Error: Name too short'))

        const request = requestUpdateUpload({ external_id: external_id, name: value })
        request.then((response) => {
            dispatch(setProductions(response.data))
            dispatch(setFlash(`Changes saved`))
        }).catch((error) => {
            dispatch(setFlash('Failed'))
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
        document.activeElement.blur()
    }

    // Rendering -----

    if (!production) return <div></div>

    return (
        <div className={`panel float-left ${view}-view`}>
            <h4>Uploads</h4>
            <div className='panel-head-btns'>
                <div className={`head-btn view-${view}-btn`} title="Icon or List view" onClick={() => { toggleView() }}><span /><span /><span /></div>
                <div className={`head-btn sort-btn dir-${orderDir}`} title="Change sorting" onClick={() => { toggleSort() }}>
                    {`${orderBy === 'name' ? 'Name' : 'Date'} ${orderDir}`}
                </div>
            </div>

            <div className="upload-container">

                {
                    sortedUploads().map((upload) => {
                        return <div className="upload-img" key={`upload_${upload.external_id}`}>
                            <img src={upload.file.split('?')[0]} alt={upload.name} />

                            <Form onSubmit={handleSubmit} className="production-title">
                                <TextInput
                                    name={upload.external_id}
                                    title="Name"
                                    showTitle={false}
                                    onUpdate={handleNameUpdate}
                                    placeholder="The Wire"
                                    errors={[]}
                                    required={true}
                                    defaultValue={upload.name.replace(/\..+$/, '')}
                                    inputType='text'
                                />
                            </Form>

                            <span className="date">{formatDatetime(upload.created_at)}</span>
                            <div className="row-del" onClick={() => { delPhoto(upload.external_id) }}><div className="iron-cross" /></div>
                        </div>
                    })
                }

                {inProgress && <div className="upload-img">
                    <img src='/loading.gif' alt="loading" />
                    <h5>{inProgress}</h5>
                </div>}

                {
                    !inProgress && <div className="upload-btn-holder">
                        <span />
                        <input className="upload-btn" type="file" name="upload" accept="image/jpeg,image/png,image/gif" onChange={(e) => { uploadFile(e) }} />
                    </div>
                }
            </div>

        </div >

    )
}


export default Uploads
