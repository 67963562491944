import React, { useState } from 'react'

import { getFirstName, randLastName, randWebsite, randCountry, randCompany, randCompanySlogan, randRole } from '../../lib/Utils'

function Asimov(props) {

    const { browser } = props

    const [searchStr, setSearchStr] = useState('')
    const [fullSearch, setFullSearch] = useState(false)

    const _results = () => {
        if (
            !searchStr || searchStr.length < 3
            || !browser || !browser['asimov.com']
            || !browser['asimov.com']['results']
        ) return false

        // get keys
        const keys = browser['asimov.com']['results'].filter((result) => {
            return result.title.toLowerCase().includes(searchStr.toLowerCase())
        })

        // add faux results

        for (let i = 0; i < 5; i++) {
            keys.push(fauxResult())
        }

        return keys
    }

    const fauxResult = () => {
        // generate people names
        //const person = Math.round(Math.random() * 100) > 49

        // see if there's a firstname that matches
        const names = getFirstName(searchStr)

        // if a person
        if (names.length) {
            const website = randWebsite()
            const firstName = names[Math.round(Math.random() * (names.length - 1))]
            const lastName = randLastName()
            return {
                "title": firstName + ' ' + lastName,
                "website": website,
                "content": firstName + ' ' + lastName + " is a " + randRole() + " from " + randCountry(),
                "link": website.toLowerCase().replaceAll(' ', '-') + ".com/" + firstName.toLowerCase() + '-' + lastName.toLowerCase()
            }
        }

        // is a company
        const name = randCompany()
        const website = name.toLowerCase().replaceAll(' ', '').replaceAll(',', '') + ".com"
        return {
            "title": name,
            "website": website,
            "content": randCompanySlogan(searchStr),
            "link": website + "/" + searchStr
        }
    }


    const results = _results()

    return <div className={`b browser-container ${results ? 'with-results' : 'no-results'}`} >
        <div className='top-bar'>
            <div className="tb-btn active">all</div>
            <div className="tb-btn">images</div>
        </div>
        <div className="search-title">
            <img src="/images/asimov-logo.png" alt="asimov" />
        </div>

        <div className="search-input" >
            <input
                type="text"
                className="search-field"
                value={searchStr}
                onChange={(e) => { setFullSearch(false); setSearchStr(e.target.value) }}
                onKeyDown={(e) => { if (e.key === 'Enter') { setFullSearch(true) } }}
            />
            <img src="/images/search-icon.png" alt="search" width={18} height={18} onClick={() => { setFullSearch(true) }} />
        </div>

        <div className={`results-panel ${fullSearch ? 'full-results' : ''}`}>
            <div className="quick-results-list">
                {
                    results && results.map((result, index) => <div
                        className='result'
                        key={`result_${index}`}
                        onClick={() => { }}
                    >
                        {result.title}
                    </div>)
                }
            </div>
            <div className="full-results-list">
                {
                    results && results.map((result, index) => <div
                        className='result'
                        key={`result_${index}`}
                        onClick={() => { }}
                    >
                        <div className="top-row">
                            <div className="roundel">{result.website && result.website[0].toUpperCase()}</div>
                            <div className="website">{result.website}</div>
                            <div className="link">{result.link}</div>
                        </div>

                        <div className="title">{result.title}</div>
                        <div className="content">{result.content}</div>
                    </div>)
                }
            </div>

        </div>

        {/* <div className="search-news-headlines">
            <h3>News Headlines</h3>
            <div className="news-story">
                <div className="headline">
                    Dangerous offender is released from Prison
                </div>
                <div className="news-time">
                    2 hours ago
                </div>
            </div>
            <div className="news-story">
                <div className="headline">
                    Condolences Pour In After Fatal Shooting of City Police Officers
                </div>
                <div className="news-time">
                    2 hours ago
                </div>
            </div>
        </div> */}
    </div>
}

export default Asimov