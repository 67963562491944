import { useState } from 'react'
import { ChromePicker } from 'react-color'

import DatePicker from 'react-date-picker'
import TimePicker from 'react-time-picker'
import Slider from '../inputs/Slider'
import Droplist from '../inputs/Droplist'

function Settings(props) {
    const { device, save } = props

    const [color, setColor] = useState(false)
    const [colorKey, setColorKey] = useState(false)

    function updateBatteryLevel(value) {
        let num = parseInt(value)
        if (isNaN(num)) { num = 0 }
        save({ ...device.config, batteryLevel: num })
    }

    function updateCellSignal(value) {
        let num = parseInt(value)
        if (isNaN(num)) { num = 0 }
        save({ ...device.config, cellSignal: num })
    }

    function updateWifiSignal(value) {
        let num = parseInt(value)
        if (isNaN(num)) { num = 0 }
        save({ ...device.config, wifiSignal: num })
    }

    function updateLockPin(e) {
        let pin = e.target.value.replace(/[^0-9x ]/g, "")
        save({ ...device.config, lockPin: pin })
    }

    function setIncomingCallFS(value) {
        save({ ...device.config, incomingCallFullScreen: value })
    }

    function setBroken(value) {
        save({ ...device.config, broken: value })
    }

    const setDate = (date) => {
        const savedDate = new Date(device.config.date)
        date.setHours(savedDate.getHours())
        date.setMinutes(savedDate.getMinutes())
        save({ ...device.config, date: date.toString() })
    }

    const setTime = (value) => {
        const date = new Date(device.config.date)

        let [hours, minutes] = value.split(':')
        date.setHours(parseInt(hours))
        date.setMinutes(parseInt(minutes))
        save({ ...device.config, date: date.toString() })
    }


    const saveColor = () => {
        save({ ...device.config, theme: { ...device.config.theme, [colorKey]: 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + color.a + ')' } })
        setColor(false)
        setColorKey(false)
    }

    const setSwatch = (swatch) => {
        save({ ...device.config, theme: { ...device.config.theme, color: swatch.c, background: swatch.b, highlight: swatch.h } })
    }

    const setScale = (scale) => {
        save({ ...device.config, theme: { ...device.config.theme, uiScale: scale } })
    }

    const setLockType = (_, value) => {
        console.log(value)
        save({ ...device.config, lockType: value })
    }

    const showAlarm = () => {
        window.socket.sendEvent({ event: 'setAlarm', alarm: 'Alarm' })
    }

    const lockTypes = [
        { value: 'none', lable: 'None (swipe to open)' },
        { value: 'fingerprint', lable: 'Fingerprint (any works)' },
        { value: 'fingerprint_locked', lable: 'Fingerprint (none work)' },
        { value: 'pin_any', lable: 'Pin (any works)' },
        { value: 'pin', lable: 'Pin' }
    ]

    return (
        <div className="panel">
            <div className="config-row">
                <div className="config-col">
                    <b>Cell Signal</b>
                </div>
                <div className="config-col slider-row">
                    <input className="slider-text" type="number" value={device.config.cellSignal} onChange={(e) => { updateCellSignal(e.target.value) }} />
                    <Slider min={0} max={100} onUpdate={updateCellSignal} value={device.config.cellSignal} />
                </div>
            </div>
            <div className="config-row">
                <div className="config-col">
                    <b>Wifi Signal</b>
                </div>
                <div className="config-col slider-row">
                    <input className="slider-text" type="number" value={device.config.wifiSignal} onChange={(e) => { updateWifiSignal(e.target.value) }} />
                    <Slider min={0} max={100} onUpdate={updateWifiSignal} value={device.config.wifiSignal} />
                </div>
            </div>

            <div className="config-row">
                <div className="config-col">
                    <b>Battery Level</b>
                </div>
                <div className="config-col slider-row">
                    <input className="slider-text" type="number" value={device.config.batteryLevel} onChange={(e) => { updateBatteryLevel(e.target.value) }} />
                    <Slider min={0} max={100} onUpdate={updateBatteryLevel} value={device.config.batteryLevel} />
                </div>
            </div>

            <div className="config-row">
                <div className="config-col">
                    <b>Incoming Call Full Screen</b>
                </div>
                <div className="config-col">
                    <label className="switch float-right" >
                        <input type="checkbox" checked={device.config.incomingCallFullScreen} onChange={() => { setIncomingCallFS(!device.config.incomingCallFullScreen) }} />
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>

            <div className="config-row">
                <div className='mini-row'>
                    <div className="config-col">
                        <b>Unlock Type</b><br />
                    </div>
                    <div className="config-col">
                        <Droplist options={lockTypes} value={device.config.lockType} name='lock_type' required={false} onUpdate={setLockType} />
                    </div>
                </div>
                {
                    device.config.lockType === 'pin' && <div className="mini-row">
                        <div className="config-col">
                            <b>Unlock PIN</b><br />
                        </div>
                        <div className="config-col">
                            <input className="input-field" value={device.config.lockPin} onChange={updateLockPin} />
                        </div>
                    </div>
                }
            </div>







            <div className="config-row">
                <div className="config-col">
                    <b>Date</b>
                </div>
                <div className="config-col">
                    <DatePicker onChange={setDate} value={new Date(device.config.date)} clearIcon={false} />
                </div>
            </div>

            <div className="config-row">
                <div className="config-col">
                    <b>Time</b>
                </div>
                <div className="config-col">
                    <TimePicker onChange={setTime} value={new Date(device.config.date)} clockIcon={false} clearIcon={false} />
                </div>
            </div>

            <div className="config-row">
                <div className="config-col">
                    <b>Alarm</b>
                </div>
                <div className="config-col">
                    <div className="settings-add-btn" onClick={() => { showAlarm() }}>Show Alarm</div>
                </div>
            </div>



            <div className="config-row">
                <div className="config-col">
                    <b>Colors</b>
                </div>
                <div className="config-col">
                    {
                        [

                            { c: 'rgba(204,204,204,1)', b: 'rgba(10,10,10,1)', h: 'rgba(100,155,250,1)' },
                            { c: 'rgba(10,10,10,1)', b: 'rgba(204,204,204,1)', h: 'rgba(25, 99, 226,1)' },
                            { c: 'rgba(100,14,148,1)', b: 'rgba(255,166,196,1)', h: 'rgba(127, 161, 0,1)' },
                            { c: 'rgba(7, 242, 39,1)', b: 'rgba(0,0,0,1)', h: 'rgba(210, 247, 215,1)' }
                        ].map((swatch, index) => <div
                            onClick={() => {
                                setSwatch(swatch)
                            }}
                            key={`swatch_${index}`}
                            className="swatch"
                            style={{ background: swatch.b, color: swatch.c }}
                        >
                            Text
                            <div style={{ color: swatch.h }}>Accent</div>
                        </div>)
                    }
                </div>
            </div>


            {
                color && <div>
                    <div className="matte" onClick={() => { setColor(false) }}></div>
                    <div className="modal-container">
                        <ChromePicker color={color} onChangeComplete={(c) => { setColor(c.rgb) }} />
                        <div className="btn btn-primary float-right" onClick={saveColor}>Done</div>
                    </div>
                </div>

            }

            <div className="config-row">
                <div className="config-col">
                    <b>Display Size</b>
                </div>
                <div className="config-col">
                    <div
                        className={`scale-btn scale-xl ${device.config.theme.uiScale === 1.3 ? 'selected' : ''}`}
                        onClick={() => { setScale(1.3) }}>
                        X Large
                    </div>

                    <div
                        className={`scale-btn scale-lrg ${device.config.theme.uiScale === 1.1 ? 'selected' : ''}`}
                        onClick={() => { setScale(1.1) }}>
                        Large
                    </div>


                    <div
                        className={`scale-btn scale-reg ${device.config.theme.uiScale === 1 ? 'selected' : ''}`}
                        onClick={() => { setScale(1) }}>
                        Regular
                    </div>


                    <div
                        className={`scale-btn scale-sml ${device.config.theme.uiScale === 0.9 ? 'selected' : ''}`}
                        onClick={() => { setScale(0.9) }}>
                        Small
                    </div>

                </div>
            </div>

            <div className="config-row">
                <div className="config-col">
                    <b>Broken Screen</b>
                </div>
                <div className="config-col">
                    <label className="switch float-right" >
                        <input type="checkbox" checked={device.config.broken} onChange={() => { setBroken(!device.config.broken) }} />
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>


        </div>
    )
}


export default Settings
