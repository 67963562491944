import React from 'react'
import { Row, Card, Col } from 'react-bootstrap'

import Page from '../components/layouts/Page'
import { useTitle } from '../lib/Utils'


function HowItWorks() {
    useTitle('How It Works')
    return (
        <Page>
            <div className='hero-page-content'>
                <Row className="mx-0 text-center justify-content-md-center">
                    <h1>How It Works</h1>
                </Row>

                <Row className="mx-0 justify-content-md-center mt-3">
                    <Col md={8}>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    <p>
                                        <b>1. Sign up for a propOS account <a href="/sign-up" className="active-link">here.</a></b><br />
                                        This is the control panel where you can manage all of your devices.
                                    </p>
                                    <p>
                                        <b>2. Open your Dashboard</b><br />
                                        Your dashboard shows the productions you're currently working on. Devices and assets are locked to a production, so you don't have to worry that images or devices from one production can be seen in another.
                                    </p>
                                    <img className="control-panel-img" src="/images/control-panel-03.png" alt="Manage multiple Devices in one place" />
                                    <p>
                                        <b>3. Configure a Device</b><br />
                                        Customise the device to however the scene requires. Everything from the battery level and the apps that are installed to the contacts available and the device security.
                                    </p>
                                    <img className="control-panel-img" src="/images/control-panel-01.png" alt="Configure the phone in a simple to use control panel" />
                                    <p>
                                        <b>4. Link to a phone</b><br />
                                        Install the Prop OS app on Android or iOS and enter the PIN to link the phone to the control panel.
                                        You can link multiple phones to one control panel Device.
                                    </p>
                                    <img className="control-panel-img" src="/images/control-panel-02.png" alt="Link a phone to a control panel Device." />
                                    <p>
                                        <b>That's it</b><br />
                                        Update the device config and when you sync, all phones will be updated instantly.<br />
                                        <br />
                                        You can link a phone to another Device at any point. A Device can be used for an entire season run or a single scene, once the scene is in the can, link the phone to a device that is prepared for the next scene and the prop is ready to shoot again in seconds.
                                    </p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </div>

        </Page >
    )
}

export default HowItWorks
