import React from 'react'
import { Row, Card, Col } from 'react-bootstrap'

import Page from '../components/layouts/Page'
import { useTitle } from '../lib/Utils'


function Download() {
    useTitle('Download')

    // update this to auto forward to the relevant url (when you have them)

    const getOS = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/windows phone/i.test(userAgent)) { return "Windows Phone" }
        if (/android/i.test(userAgent)) { return "Android" }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) { return "iOS" }
        return "desktop";
    }
    const os = getOS()
    return (
        <Page>
            <div className='hero-page-content'>
                <Row className="mx-0 text-center justify-content-md-center">
                    <Col md={8}>
                        <Card>
                            {(os === 'iOS' || os === 'Android') &&
                                <Card.Body>
                                    <p>Install or update PropOS</p>
                                    <div className="store-badges">
                                        {os === 'iOS' &&
                                            <a href='https://apps.apple.com/us/app/prop-os/id6446000333' target="_blank" rel="noreferrer">
                                                <img alt='Download on the App Store' src='/images/apple-badge.png' />
                                            </a>
                                        }
                                        {os === 'Android' &&
                                            <a href='https://play.google.com/store/apps/details?id=com.propos' target="_blank" rel="noreferrer">
                                                <img alt='Get it on Google Play' src='/images/google-badge.png' />
                                            </a>
                                        }
                                    </div>
                                </Card.Body>
                            }
                            {(os !== 'iOS' && os !== 'Android') &&
                                <Card.Body>
                                    <h4>Looks like you're using a device we don't support.</h4>
                                    <p>PropOS has apps for iOS and Android.</p>
                                </Card.Body>
                            }
                        </Card>
                    </Col>
                </Row>

            </div>

        </Page >
    )
}

export default Download
