import { useState } from 'react'

function Contacts(props) {
    const { device, save, setImageKey, startCall } = props

    const [showPanels, setShowPanels] = useState([])

    function createNewCont() {
        const cont = {
            name: 'contact',
            image: '',
            phone: '',
            email: '',
            address: ''
        }

        save({ ...device.config, contacts: [...device.config.contacts, cont] })
    }

    function delContact(index) {
        if (!window.confirm('Are you sure?')) return

        const conts = [...device.config.contacts]
        conts.splice(index, 1)
        save({ ...device.config, contacts: conts })
    }

    function updateContName(name, index) {
        const newCont = { ...device.config.contacts[index], name: name }
        save({ ...device.config, contacts: device.config.contacts.map((existing, i) => i === index ? newCont : existing) })
    }

    function togglePanel(index) {
        if (showPanels.includes(index)) {
            setShowPanels([...showPanels].filter(function (e) { return e !== index }))
        } else {
            setShowPanels([...showPanels, index])
        }
    }

    function sortConts() {
        const sorted = [...device.config.contacts]
        sorted.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        save({ ...device.config, contacts: sorted })
    }

    function updateField(index, key, value) {
        const newCont = { ...device.config.contacts[index], [key]: value }
        save({ ...device.config, contacts: device.config.contacts.map((existing, i) => i === index ? newCont : existing) })
    }

    if (!device) return <div></div>

    return (
        <div className="panel dragging-panel">
            <div className="config-row">
                <div className="config-col">
                    <p>
                        These are the contacts the user will see when they open the contacts app. they are ordered by name
                    </p>

                    {
                        device.config.contacts &&
                        device.config.contacts.map((contact, index) => {
                            return <div key={`cont_${index}`}>
                                <div className='list-item'>
                                    <img className="icon avatar" alt={contact.name} onClick={() => { setImageKey(`contacts:${index}:image`) }} src={contact.image ? contact.image : '/unknown_contact.png'} />
                                    <div className="title">
                                        <input
                                            className="text-input"
                                            value={contact.name}
                                            onClick={(e) => { e.stopPropagation() }}
                                            onChange={(e) => { updateContName(e.target.value, index) }}
                                            onBlur={(e) => { sortConts() }}
                                        />
                                    </div>

                                    <div className={`meatball ${showPanels.includes(index) ? 'active' : ''}`} onClick={() => { togglePanel(index) }}>
                                        <div className="mb-circle" /> <div className="mb-circle" /> <div className="mb-circle" />
                                    </div>
                                    <div className="row-del" onClick={() => { delContact(index) }}><div className="iron-cross" /></div>
                                </div>

                                <div className={`row-panel ${showPanels.includes(index) ? 'open' : ''}`}>
                                    <div className="config-row">
                                        <div className="config-col">
                                            <b>Phone</b><br />
                                        </div>
                                        <div className="config-col">
                                            <input className="input-field" value={device.config.contacts[index].phone} onChange={(e) => { updateField(index, 'phone', e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="config-row">
                                        <div className="config-col">
                                            <b>Email</b><br />
                                        </div>
                                        <div className="config-col">
                                            <input className="input-field" value={device.config.contacts[index].email} onChange={(e) => { updateField(index, 'email', e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="config-row">
                                        <div className="config-col">
                                            <b>Address</b><br />
                                        </div>
                                        <div className="config-col">
                                            <input className="input-field" value={device.config.contacts[index].address} onChange={(e) => { updateField(index, 'address', e.target.value) }} />
                                        </div>
                                    </div>

                                    <div className="config-row">
                                        <div className="config-col">
                                            <img className="phone-action-icon float-right" alt="call" src="/call_answer.png" onClick={() => { startCall(contact.name, contact.image) }} />
                                            <div className="float-left bold">Incoming call from {contact.name}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        })
                    }

                    <div className="settings-add-btn" onClick={() => { createNewCont('') }}>New Contact</div>

                </div>
            </div>
        </div>
    )
}


export default Contacts
