import { setDeviceConfig, addMsg, setOnCall, setSynced, setSaved } from '../features/user/homeSlice'

const socket = {
    connected: false,
    init: function (dispatch, production_id, device_id) {
        this.dispatch = dispatch
        this.production_id = production_id
        this.device_id = device_id
        return this
    },
    connect: function (device_id) {
        console.log('connecting')

        // open websocket
        const endpoint = process.env.NODE_ENV === `development` ?
            'ws://' + window.location.hostname + ':8000/ws/' + device_id + '/' :
            'wss://' + window.location.hostname + '/ws/' + device_id + '/'

        //const endpoint = 'wss://www.prop-os.com/device/' + device_id + '/'

        this.connection = new WebSocket(endpoint)

        this.connection.onopen = function (e) {
            this.connected = true
        }.bind(this)

        this.connection.onmessage = function (e) {
            const obj = JSON.parse(e.data)

            if (obj.received) {
                this.dispatch(setSynced(true))
                this.dispatch(setSaved(true))
                return
            }

            const data = JSON.parse(obj)
            if (data.type === 'config') {
                this.dispatch(setDeviceConfig({ production_id: this.production_id, device_id: this.device_id, config: JSON.parse(data.payload) }))
            }


            if (data.type === 'event') {
                console.log(data.payload)
                if (data.payload && data.payload.event === 'threadedMessage') {
                    this.dispatch(addMsg({
                        production_id: this.production_id,
                        device_id: this.device_id,
                        msg_id: data.payload.index,
                        text: data.payload.message
                    }))
                    return
                }
                if (data.payload && data.payload.event === 'terminateCall') {
                    this.dispatch(setOnCall(false))
                    return
                }
                // dispatch(setEvent(data.payload))
            }

            if (data.type === 'onCall') {
                this.dispatch(setOnCall(true))
                return
            }

            if (data.type === 'endCall') {
                this.dispatch(setOnCall(null))
                return
            }

        }.bind(this)
    },
    send: function (message) {
        console.log('sending')
        if (this.connection.readyState !== 1) {

        }
        this.connection.send(JSON.stringify(message))
    },

    disconnect: function () {
        console.log('disconnecting')
        this.connection.close()
    },

    sendConfig: function (json) {
        this.send(
            {
                type: 'config',
                payload: json
            }
        )
    },

    sendEvent: function (json) {
        this.send(
            {
                type: 'event',
                payload: json
            }
        )
    }
}


export default socket
