import axios from 'axios'
import Cookies from 'js-cookie'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

export function getToken() {
  return Cookies.get('AuthToken')
}

export function setToken(token) {
  return Cookies.set('AuthToken', token, { expires: 90000 })
}

export function removeToken() {
  return Cookies.remove('AuthToken')
}

export function rootUrl() {
  if (process.env.NODE_ENV === `development`) {
    return 'http://' + window.location.hostname + ':8000'
  }
  return ``
}

export function requestSignUp(params) {
  return axios.post(`${rootUrl()}/api/sign-up`, params)
}

export function requestLogin(params) {
  return axios.post(`${rootUrl()}/api/login`, params)
}

export function requestTokenUser(token) {
  return axios.get(
    `${rootUrl()}/api/token-sync`,
    { headers: { Authorization: `Token ${token}` } }
  )
}

export function requestBrwoserData(device_id) {
  return axios.get(
    `${rootUrl()}/api/browser-data/${device_id}`
  )
}

export function requestNewProduction(params) {
  return axios.post(
    `${rootUrl()}/api/new-production`,
    params,
    {
      headers: { Authorization: `Token ${getToken()}` }
    })
}

export function requestUpdateProduction(params) {
  return axios.post(
    `${rootUrl()}/api/production`,
    params,
    {
      headers: { Authorization: `Token ${getToken()}` }
    })
}

export function requestDeleteUpload(external_id) {
  return axios.delete(
    `${rootUrl()}/api/upload/${external_id}`,
    {
      headers: { Authorization: `Token ${getToken()}` }
    })
}

export function requestUpdateUpload(params) {
  return axios.put(
    `${rootUrl()}/api/update-upload`,
    params,
    {
      headers: { Authorization: `Token ${getToken()}` }
    })
}



export function requestNewDevice(params) {
  return axios.post(
    `${rootUrl()}/api/new-device`,
    params,
    {
      headers: { Authorization: `Token ${getToken()}` }
    })
}

export function requestNewHandset(params) {
  return axios.post(
    `${rootUrl()}/api/new-handset`,
    params,
    {
      headers: { Authorization: `Token ${getToken()}` }
    })
}

export function requestUpload(file, production_id) {
  var formData = new FormData()
  formData.append("file", file)
  formData.append("production_id", production_id)
  formData.append("name", file.name)
  return axios.post(`${rootUrl()}/api/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Token ${getToken()}`
    }
  })
}

export function requestSaveConfig(device_id, config) {
  return axios.post(
    `${rootUrl()}/api/device/${device_id}/config`,
    config,
    {
      headers: { Authorization: `Token ${getToken()}` }
    })
}

export function requestUpdateDevice(external_id, params) {
  return axios.post(
    `${rootUrl()}/api/device/${external_id}`,
    params,
    {
      headers: { Authorization: `Token ${getToken()}` }
    })
}

export function requestDeleteDevice(external_id) {
  return axios.delete(
    `${rootUrl()}/api/device/${external_id}/delete`,
    {
      headers: { Authorization: `Token ${getToken()}` }
    })
}

export function requestCloneDevice(external_id) {
  return axios.post(
    `${rootUrl()}/api/device/${external_id}/clone`, {},
    {
      headers: { Authorization: `Token ${getToken()}` }
    })
}

// -- Buckles

export function requestBuckles() {
  return axios.get(
    `${rootUrl()}/api/buckles/`,
    {
      headers: { Authorization: `Token ${getToken()}` }
    })
}

export function requestBucklesUpdateUser(params) {
  return axios.post(
    `${rootUrl()}/api/buckles/user`,
    params,
    {
      headers: { Authorization: `Token ${getToken()}` }
    })
}

export function requestBucklesProductions(id) {
  return axios.get(
    `${rootUrl()}/api/buckles/productions/${id}`,
    {
      headers: { Authorization: `Token ${getToken()}` }
    })
}



