import { configureStore } from '@reduxjs/toolkit'

import userReducer from '../features/user/homeSlice'

export const store = configureStore({
  reducer: {
    home: userReducer
  },
  devTools: process.env.NODE_ENV === 'development',
})

export default store
