import { useState } from 'react'

function Notifications(props) {
    const { device, save } = props
    const [dragging, setDrag] = useState({})
    const [showPanels, setShowPanels] = useState([])
    const [iconIndex, setIconIndex] = useState(false)

    const availableIcons = [
        'phone',
        'messages',
        'maps',
        'settings',
        'browser',
        'contacts',
        'tv',
        'youmoov',
        'podcasts',
        'health',
        'mail',
        'outlast',
        'news',
        'sport',
        'books',
        'vid_call',
        'weather',
        'calendar',
        'maps'
    ]

    function togglePanel(index) {
        if (showPanels.includes(index)) {
            setShowPanels([...showPanels].filter(function (e) { return e !== index }))
        } else {
            setShowPanels([...showPanels, index])
        }
    }

    const dragStart = (e, index) => {
        e.dataTransfer.setDragImage(document.createElement('span'), 0, 0)
        setDrag(index)
    }

    const dragEnter = (e, target) => {
        if (dragging === undefined || dragging === target) return


        const notifications = [...device.config.pushNotifications]
        notifications.splice(dragging, 1)
        notifications.splice(target, 0, device.config.pushNotifications[dragging])
        save({ ...device.config, pushNotifications: notifications })

        setDrag(target)
    }

    const dragEnd = () => {
        setDrag({})
    }

    const delApp = (from, index) => {
        const apps = [...device.config[from]]
        apps.splice(index, 1)
        save({ ...device.config, [from]: apps })
    }

    function updateField(index, key, value) {
        const newItem = { ...device.config.pushNotifications[index], [key]: value }
        save({ ...device.config, pushNotifications: device.config.pushNotifications.map((existing, i) => i === index ? newItem : existing) })
    }

    const setIcon = (icon) => {
        updateField(iconIndex, 'icon', `https://www.prop-os.com/apps/${icon}.png`)
        setIconIndex(false)
    }

    const newNotification = () => {
        const notification = {
            icon: "https://www.prop-os.com/apps/phone.png",
            appName: "phone",
            title: 'Missed Call',
            text: 'from Mum',
            when: "now"
        }

        save({ ...device.config, pushNotifications: [...device.config.pushNotifications, notification] })
    }

    const showNotifications = () => {
        window.socket.sendEvent({ event: 'showNotifications' })
    }

    if (!device) return <div></div>

    const content = (str) => {
        if (!str.includes('attachment')) {
            return <div className="text">{str}</div>
        }

        return <img className="img" width={80} alt="attachment" src={str.split('::')[1]} />
    }

    return (
        <div className="panel">
            <div className="config-row">
                <div className="config-col">
                    <h5>Notifications</h5>
                    <p>
                        These are the notifications in the order that they will appear in the notifications draw on the device.
                    </p>

                    {
                        device.config.pushNotifications.map((notification, index) => {
                            return <div className="notification-row" key={`notification_${index}`} onDragEnter={(e) => dragEnter(e, index)} >

                                <div className="actions">
                                    <div
                                        className='drag-icon'
                                        draggable
                                        onClick={(e) => { e.stopPropagation() }}
                                        onDragStart={(e) => dragStart(e, index)}
                                        onDragEnd={dragEnd}
                                    />
                                    <div className="row-del" onClick={() => { delApp('pushNotifications', index) }}><div className="iron-cross" /></div>
                                </div>


                                <div className={`notification ${index === dragging ? 'active-app-item' : ''}`} onClick={() => { togglePanel(index) }}>
                                    <div className="when">{notification.when}</div>
                                    <div className="top-row">
                                        <img className="icon" alt={notification.name} src={notification.icon} />
                                        <div className="notification-title">{notification.appName.toUpperCase()}</div>
                                    </div>

                                    <div className="title">{notification.title}</div>
                                    {content(notification.text)}
                                </div>
                                {/* --- PANEL --- */}
                                <div className={`row-panel ${showPanels.includes(index) ? 'open' : ''}`}>
                                    <div className="config-row">
                                        <div className="config-col">
                                            <b>Icon</b><br />
                                        </div>
                                        <div className="config-col">
                                            <img className="icon float-right" width={50} alt={notification.name} src={notification.icon} onClick={() => { setIconIndex(index) }} />
                                        </div>
                                    </div>
                                    <div className="config-row">
                                        <div className="config-col">
                                            <b>App Name</b><br />
                                        </div>
                                        <div className="config-col">
                                            <input className="input-field" value={device.config.pushNotifications[index].appName.toUpperCase()} onChange={(e) => { updateField(index, 'appName', e.target.value.toLowerCase()) }} />
                                        </div>
                                    </div>
                                    <div className="config-row">
                                        <div className="config-col">
                                            <b>Headline</b><br />
                                        </div>
                                        <div className="config-col">
                                            <input className="input-field" value={device.config.pushNotifications[index].title} onChange={(e) => { updateField(index, 'title', e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="config-row">
                                        <div className="config-col">
                                            <b>Content</b><br />
                                        </div>
                                        <div className="config-col">
                                            <input className="input-field" value={device.config.pushNotifications[index].text} onChange={(e) => { updateField(index, 'text', e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="config-row">
                                        <div className="config-col">
                                            <b>When</b><br />
                                        </div>
                                        <div className="config-col">
                                            <input className="input-field" value={device.config.pushNotifications[index].when} onChange={(e) => { updateField(index, 'when', e.target.value) }} />
                                        </div>
                                        <div className="when-shortcuts" title="these are just shortcuts, you can define a specific day or duration ago in the box above">
                                            <div onClick={() => { updateField(index, 'when', 'now') }}>now</div>
                                            <div onClick={() => { updateField(index, 'when', '5 minutes ago') }}>5 minutes ago</div>
                                            <div onClick={() => { updateField(index, 'when', '1 hour ago') }}>1 hour ago</div>
                                            <div onClick={() => { updateField(index, 'when', 'yesterday') }}>yesterday</div>
                                            <div onClick={() => { updateField(index, 'when', '3 days ago') }}>3 days ago</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                    <div className="settings-add-btn" onClick={() => { newNotification() }}>New Notification</div>
                    {device.config.pushNotifications.length > 0 &&
                        <div className="settings-add-btn red" onClick={() => { showNotifications() }}>Show on Phone</div>
                    }

                </div>
            </div>


            {
                iconIndex !== false && <div>
                    <div className="matte" onClick={() => { setIconIndex(false) }}></div>
                    <div className="modal-container">
                        <h4>Notification Icon</h4>
                        <div className="icon-list">
                            {
                                availableIcons.map((icon, index) => {
                                    return <img
                                        className="app-icon"
                                        key={`app_icon_${index}`}
                                        alt={icon}
                                        src={`/apps/${icon}.png`}
                                        onClick={() => { setIcon(icon) }}
                                    />
                                })
                            }
                        </div>
                    </div>
                </div>

            }
        </div>
    )
}


export default Notifications
