function WifiSignal(props) {
    const { color, wifisignal } = props

    const nilColor = 'rgba(100,100,100,0.4)'

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 337.9965 238.9998" {...props}>
            <path fill={wifisignal > 75 ? color : nilColor} d="M0,70.0015l32.5269,32.5269c75.2506-75.2506,197.6922-75.25,272.9427,0l32.5269-32.5269A238.9991,238.9991,0,0,0,0,70.0015Z" />
            <path fill={wifisignal > 50 ? color : nilColor} d="M47.58,117.581l32.5269,32.5269a125.8545,125.8545,0,0,1,177.7837,0l32.5269-32.5269C223.4663,50.6305,114.53,50.6306,47.58,117.581Z" />
            <path fill={wifisignal > 25 ? color : nilColor} d="M95.6086,165.61l32.5269,32.5269a57.854,57.854,0,0,1,81.7259,0L242.3883,165.61A103.9064,103.9064,0,0,0,95.6086,165.61Z" />
            <path fill={wifisignal > 10 ? color : nilColor} d="M138.6917,208.6932,168.9983,239l30.3065-30.3065A42.9085,42.9085,0,0,0,138.6917,208.6932Z" />
        </svg>
    )
}


export default WifiSignal
