import Validations from './Validations'

// a cheeky bespoke validation service
const Validator = (validation_object, values) => {

    let errorMessages = {}

    for(const field in values){
        const value = values[field]
        const validation = validateField(validation_object, field, value)

        if(!validation.valid){
            errorMessages[field] = validation.message
        }
    }

  return errorMessages
}

const validateField = (validation_object, name, value) => {
    const validations = Validations[validation_object][name]
    if(validations === undefined) return {valid: true}
    const errorMessage = getErrorMessage(validations, value)

    return {valid: (errorMessage === 0), message: errorMessage}
}

const getErrorMessage = (validations, value) => {
    for(const i in validations){
        const v = validations[i]

        if(v.key === 'MIN' && value < v.value) return v.message
        if(v.key === 'MAX' && value > v.value) return v.message
        if(v.key === 'MIN_LENGTH' && (value == null || value.length < v.value)) return v.message
        if(v.key === 'MAX_LENGTH' && (value == null || value.length > v.value)) return v.message
        if(v.key === 'NOT_MATCH_PATTERN' && !v.value.test(value.toLowerCase()) ) return v.message
        if(v.key === 'MATCH_PATTERN' && value && v.value.test(value.toLowerCase().replace(/\s+/g, '')) ) return v.message
        if(v.key === 'IS_DATE' && !v.value.test(value) ) return v.message
        if(v.key === 'BOOL'    && v.value !== value ) return v.message
    }
    return 0
}


export default Validator
