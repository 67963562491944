import { useState } from 'react'
import { Form } from 'react-bootstrap'
import TextInput from '../../components/inputs/Text'

function Apps(props) {
    const { device, save } = props
    const [dragging, setDrag] = useState({})
    const [addTo, setAddTo] = useState(false)

    const availableApps = {
        'Entertainment': [
            { name: 'Photos', available: true },
            { name: 'Music', slug: 'music_01', available: false },
            { name: 'TV', available: false },
            { name: 'YouMov', available: false, },
            { name: 'Streamberry', available: false, },
            { name: 'Podcasts', available: false },
            { name: 'Fruitfly', slug: 'fruitfly_01', available: false },
            { name: 'Steinberg', available: false }
        ],
        'Games': [
            { name: 'Candy King', available: false },
            { name: 'Psycho Frog', available: false },
            { name: 'Temple', slug: 'temple_01', available: false },
            { name: 'Dice', slug: 'dice_01', available: false },
            { name: 'Bomman', slug: 'bomman_01', available: false },
        ],
        'Information': [

        ],
        'Health & Fitness': [
            { name: 'Health', available: false },
            { name: 'Mindfulness', slug: 'mindfulness_01', available: false },
            { name: 'Miso', slug: 'miso_01', available: false },
        ],
        'Productivity': [
            { name: 'Mail', available: true },
            { name: 'Outlast', available: false },
            { name: 'Notes', available: false }
        ],
        'Reading': [
            { name: 'Browserly', slug: 'browser_01', available: true },
            { name: 'News', available: false },
            { name: 'Sport', available: false },
            { name: 'Books', slug: 'books_01', available: false },
        ],
        'Social': [
            { name: 'Messages', available: true },
            { name: 'aChat', available: false },
            { name: 'Vid Call', available: false },
            { name: 'TakTik', available: false },
            { name: 'The Gram', available: false },
            { name: 'Finder', available: false },
            { name: 'Prider', slug: 'prider_01', available: false },
        ],
        'Travel': [

        ],
        'Utilities': [
            { name: 'Phone', available: true },
            { name: 'Contacts', available: true },
            { name: 'Weather', available: false },
            { name: 'Calculator', available: false },
            { name: 'Calendar', available: false },
            { name: 'App Store', available: false },
            { name: 'Maps', slug: 'maps_01', available: false },
            { name: 'Camera', available: false },
            { name: 'Asimov', available: false },
            { name: 'Slick', slug: 'slick_01', available: false },
            { name: 'Stocks', available: false },
        ]

    }

    const dragStart = (appIndex, screenIndex) => {
        setDrag({ appIndex: appIndex, screenIndex: screenIndex })
    }

    const addHomescreen = () => {
        save({ ...device.config, homeApps: [...device.config.homeApps, []] })
    }

    const delHomescreen = (index) => {
        const homescreens = [...device.config.homeApps]
        homescreens.splice(index, 1)

        // add settings to home[0] if deleted screen contains settings
        if (device.config.homeApps[index].includes('settings')) {
            homescreens[0] = [...device.config.homeApps[0], 'settings']
        }

        save({ ...device.config, homeApps: homescreens })
    }

    const dragHomeEnter = (targetAppIndex, targetScreenIndex) => {
        if (targetAppIndex < 1) targetAppIndex = 0
        if (dragging.appIndex === undefined || dragging.appIndex === targetAppIndex) return


        const homescreens = [...device.config.homeApps]

        const originApps = [...device.config.homeApps[dragging.screenIndex]]
        originApps.splice(dragging.appIndex, 1)

        // don't get it from state if we have modified the target arr
        let targetApps = originApps
        if (dragging.screenIndex !== targetScreenIndex) {
            targetApps = [...device.config.homeApps[targetScreenIndex]]
        }

        targetApps.splice(targetAppIndex, 0, device.config.homeApps[dragging.screenIndex][dragging.appIndex])

        homescreens[dragging.screenIndex] = originApps
        homescreens[targetScreenIndex] = targetApps

        save({ ...device.config, homeApps: homescreens })

        setDrag({ appIndex: targetAppIndex, screenIndex: targetScreenIndex })
    }

    const dragDockEnter = (target) => {
        if (dragging.appIndex === undefined || dragging.appIndex === target) return

        const apps = [...device.config.dockApps]
        apps.splice(dragging.appIndex, 1)
        apps.splice(target, 0, device.config.dockApps[dragging.appIndex])


        save({ ...device.config, dockApps: apps })
        setDrag({ appIndex: target, screenIndex: dragging.screenIndex })
    }

    const dragEnd = (e) => {
        e.preventDefault()
        setDrag({})
    }

    const touchTap = (e, appIndex, screenIndex) => {
        e.stopPropagation()

        if (dragging.appIndex !== undefined) {

            if (screenIndex === 'dockApps') {
                dragDockEnter(appIndex)
            } else {
                dragHomeEnter(appIndex, screenIndex)
            }

            setDrag({})
            return
        }
        setDrag({ appIndex: appIndex, screenIndex: screenIndex, touch: true })
    }

    const universalDelete = () => {
        delApp(dragging.screenIndex, dragging.appIndex)
        setDrag({})
    }

    const delApp = (from, index) => {
        if (from === 'dockApps') {
            const apps = [...device.config.dockApps]
            apps.splice(index, 1)
            return save({ ...device.config, dockApps: apps })
        }

        const apps = [...device.config.homeApps[from]]
        apps.splice(index, 1)

        const homescreens = [...device.config.homeApps]
        homescreens[from] = apps
        save({ ...device.config, homeApps: homescreens })
    }

    const addApp = (app) => {
        if (addTo === 'dockApps') {
            save({ ...device.config, dockApps: [...device.config.dockApps, { name: app.name, slug: app.slug, badge: 0 }] })
        } else {
            const homescreens = [...device.config.homeApps]
            homescreens[addTo] = [...device.config.homeApps[addTo], { name: app.name, slug: app.slug, badge: 0 }]
            save({ ...device.config, homeApps: homescreens })
        }
        setAddTo(false)
    }

    const iconUrl = (app) => {
        const slug = app.slug ? app.slug : app.name.toLowerCase().replace(' ', '_')
        return `/apps/${slug}.png`
    }

    function handleBadgeUpdate(name, value, screenIndex) {

        if (value === '') value = '0'
        const parsed = parseInt(value)
        if (isNaN(parsed)) return

        if (screenIndex === 'dockApps') {
            save({ ...device.config, dockApps: device.config.dockApps.map(obj => obj.name === name ? { name: name, badge: parsed } : obj) })
        } else {
            const homescreens = [...device.config.homeApps]
            homescreens[screenIndex] = device.config.homeApps[screenIndex].map(obj => obj.name === name ? { name: name, badge: parsed } : obj)
            save({ ...device.config, homeApps: homescreens })
        }
    }

    function handleSubmit(e) {
        e.preventDefault()
        document.activeElement.blur()
    }

    // --- Render ----

    if (!device) return <div></div>

    // stop snap back for dragging

    document.ondragover = (e) => {
        e.preventDefault()
    }



    return (
        <div className="panel">
            <div className="config-row">
                <div className="config-col">
                    <p>
                        These are the Apps that appear to be installed on the device. They will be displayed in this order, but the number of apps per row may differ for different sized devices.
                    </p>


                    <div className='homescreen-container'>
                        <div className="screen-name">
                            Dock
                        </div>

                        {
                            device.config.dockApps.map((app, index) => {
                                return <div
                                    className={`app-item ${index === dragging.appIndex && dragging.screenIndex === 'dockApps' ? 'active-app-item' : ''}`}
                                    key={`app_${index}`}
                                    onDragEnter={(e) => dragDockEnter(index)}
                                    onDragOver={(e) => { e.preventDefault() }}
                                    draggable
                                    onClick={(e) => { e.stopPropagation() }}
                                    onDragStart={(e) => dragStart(index, 'dockApps')}
                                    onTouchEnd={(e) => { touchTap(e, index, 'dockApps') }}
                                    onDragEnd={dragEnd}
                                >
                                    <img className="icon" alt={app.name} src={iconUrl(app)} />
                                    <div className="title">{app.name}</div>
                                    <div className="row-del" onClick={() => { delApp('dockApps', index) }}><div className="iron-cross" /></div>

                                    <Form onSubmit={handleSubmit} className={`app-badge-form ${app.badge > 0 ? 'persistent-badge' : 'not-persistent'}`}>
                                        <TextInput
                                            name={app.name}
                                            title=""
                                            showTitle={false}
                                            onUpdate={(name, value) => { handleBadgeUpdate(name, value, 'dockApps') }}
                                            placeholder=""
                                            errors={[]}
                                            required={true}
                                            defaultValue={app.badge}
                                            inputType='text'
                                        />
                                    </Form>
                                </div>
                            })
                        }

                        {
                            device.config.dockApps.length < 4 && <div
                                className="app-item add-app"
                                onClick={() => { setAddTo('dockApps') }}
                                onDragEnter={(e) => dragHomeEnter(device.config.dockApps.length - 1, 'dockApps')}
                                onTouchEnd={(e) => { touchTap(e, device.config.dockApps.length - 1, 'dockApps') }}
                            >
                                <span />
                                Add
                            </div>
                        }
                    </div>

                    {/* ------------- HOMESCREENS  */}

                    {

                        device.config.homeApps.map((appArr, screenIndex) => {
                            return <div key={`screen_${screenIndex}`} className='homescreen-container'>
                                <div className="screen-name">
                                    Home screen {screenIndex + 1}
                                    <div className="btns">
                                        {screenIndex > 0 && <div className="row-del" title="Delete homescreen" onClick={() => { delHomescreen(screenIndex) }}><div className="iron-cross" /></div>}

                                    </div>

                                </div>

                                {appArr.map((app, index) => {

                                    return <div
                                        className={`app-item ${index === dragging.appIndex && dragging.screenIndex === screenIndex ? 'active-app-item' : ''}`}
                                        key={`screen_${screenIndex}_app_${index}`}
                                        onDragEnter={(e) => dragHomeEnter(index, screenIndex)}
                                        draggable
                                        onClick={(e) => { e.stopPropagation() }}
                                        onDragStart={(e) => dragStart(index, screenIndex)}
                                        onDragEnd={dragEnd}
                                        onTouchEnd={(e) => { touchTap(e, index, screenIndex) }}
                                    >
                                        <img className="icon" alt={app.name} src={iconUrl(app)} />
                                        <div className="title">{app.name}</div>
                                        {
                                            app.name !== 'settings' && <div className="row-del" onClick={(e) => { delApp(screenIndex, index) }}><div className="iron-cross" /></div>
                                        }

                                        <Form onSubmit={handleSubmit} className={`app-badge-form ${app.badge > 0 ? 'persistent-badge' : 'not-persistent'}`}>
                                            <TextInput
                                                name={app.name}
                                                title=""
                                                showTitle={false}
                                                onUpdate={(name, value) => { handleBadgeUpdate(name, value, screenIndex) }}
                                                placeholder=""
                                                errors={[]}
                                                required={true}
                                                defaultValue={app.badge}
                                                inputType='text'
                                            />
                                        </Form>
                                    </div>
                                })}

                                <div
                                    className="app-item add-app"
                                    onClick={() => { setAddTo(screenIndex) }}
                                    onDragEnter={(e) => dragHomeEnter(appArr.length - 1, screenIndex)}
                                    onTouchEnd={(e) => { touchTap(e, appArr.length - 1, screenIndex) }}
                                >
                                    <span />
                                    Add
                                </div>
                            </div>
                        })


                    }

                    <div className="settings-add-btn float-right" onClick={() => { addHomescreen() }}>New Home screen</div>

                </div>

                {
                    dragging && dragging.touch && <div className="universal-del" onClick={universalDelete}><div className="iron-cross" /></div>
                }
            </div>

            {
                addTo !== false && < div >
                    <div className="matte" onClick={() => { setAddTo(false) }}></div>
                    <div className="modal-container">
                        <h4>Add an App</h4>
                        <p className='modal-msg'>This is a list of available Apps that have not yet been added to the device. <br />
                            Only highlighted Apps are currently functional.</p>
                        {
                            Object.keys(availableApps).sort().map((category) => {
                                const installedApps = [device.config.dockApps, device.config.homeApps].flat(2).map(a => a.name)

                                const uninstalledApps = [...availableApps[category]].filter(a => !installedApps.includes(a.name))

                                if (uninstalledApps.length < 1) return <div key={`install_app_category_${category}`} />

                                return (<div className='install-app-category' key={`install_app_category_${category}`}>
                                    <div className='install-app-title'>{category}</div>
                                    {
                                        uninstalledApps.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((appObj, index) => {

                                            return <div className={`install-app-btn ${appObj.available ? 'available' : 'unavailable'}`} key={`available_apps_${index}`} onClick={() => { addApp(appObj) }}>
                                                <img className="app-icon" alt={appObj.name} src={iconUrl(appObj)} />
                                                <div className="app-title">{appObj.name}</div>
                                            </div>
                                        })
                                    }
                                </div>)

                            })


                        }
                    </div>
                </div>

            }

        </div>
    )
}


export default Apps
