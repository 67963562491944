import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from "react-router-dom"

import { setProductions, setFlash } from '../features/user/homeSlice'
import { Form } from 'react-bootstrap'

import { requestUpdateProduction } from '../lib/Api'

import TextInput from '../components/inputs/Text'
import Page from '../components/layouts/Page'

import Validator from '../lib/Validator'
import Uploads from '../components/uploads'
import Devices from '../components/devices'


function Production(props) {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.home.user)
    const [production, setProduction] = useState(false)

    const [values, setValues] = useState({ name: '' })
    const [errors, setErrors] = useState({})
    const validation_object = 'device'

    useEffect(() => {
        if (user.token == null) {
            return
        }

        // get id from url params
        const production_id = props.match.params.production_id

        // get production from profile
        const prod = user.profile.productions.filter(prod => { return prod.external_id === production_id })[0]
        if (!prod && user.isStaff) { return window.location.replace('/buckles') }
        if (!prod) { return window.location.replace('/') }
        setProduction(prod)
        setValues({ name: prod.name, external_id: prod.external_id })
        document.title = `PropOS - ${prod.name}`

    }, [user.token, user.profile.productions, props.match.params.production_id])

    useEffect(() => () => { document.title = 'PropOS' }, [])

    const handleNameUpdate = (name, value) => {
        const newValues = { ...values, [name]: value }
        setValues(newValues)
        setErrors(Validator(validation_object, newValues))
        send(newValues)
    }

    function handleSubmit(e) {
        e.preventDefault()
        document.activeElement.blur()
    }

    function send(vals) {
        const errors = Validator(validation_object, vals)
        if (Object.keys(errors).length) return false

        const request = requestUpdateProduction(vals)
        request.then((response) => {
            dispatch(setProductions(response.data))
            dispatch(setFlash(`Production saved`))
        }).catch((error) => {
            dispatch(setFlash('Failed'))
        })
    }

    if (!user.token)
        return <Page><Link to="/login">Sign In Required</Link></Page>

    if (!production)
        return <Page>Loading...</Page>

    return (
        <Page>
            <Link className="back-btn" to='/dashboard'>Back</Link>
            <Form onSubmit={handleSubmit} className="production-title">
                <TextInput
                    name="name"
                    title="Name"
                    showTitle={false}
                    onUpdate={handleNameUpdate}
                    placeholder="Great Scott"
                    errors={errors}
                    required={true}
                    defaultValue={production.name}
                    inputType='text'
                />
            </Form>

            <div className='col'>
                <Devices production={production} />
            </div>

            <div className='col'>
                <Uploads production={production} />
            </div>

        </Page >
    )
}

export default Production
