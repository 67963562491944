import React from 'react'
import { Row, Card, Col } from 'react-bootstrap'

import Page from '../components/layouts/Page'
import { useTitle } from '../lib/Utils'


function Terms() {
    useTitle('Terms & Conditions')
    return (
        <Page>
            <div className='hero-page-content'>
                <Row className="mx-0 text-center justify-content-md-center">
                    <h1>Terms & Conditions</h1>
                </Row>

                <Row className="mx-0 justify-content-md-center mt-3">
                    <Col md={8}>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    <p>
                                        <b>Before we start...</b><br />
                                        Using the prop-OS apps in a manner in which is it unclear that it is a fiction will cause your account to be terminated immediately. <br />
                                        <br />
                                        <i>Don't try to trick people with the prop is what I'm saying.</i>
                                    </p>
                                    <p>
                                        <b>1. Introduction</b><br />
                                        This website and accompanying mobile apps (software) is operated by James White. The terms “we”, “us”, and “our” refer to James White. The use of our software is subject to the following terms and conditions of use, as amended from time to time (the “Terms”). The Terms are to be read together by you with any terms, conditions or disclaimers provided in the pages of our software. Please review the Terms carefully. The Terms apply to all users of our software, including without limitation, users who are browsers, customers, merchants, vendors and/or contributors of content. If you access and use this software, you accept and agree to be bound by and comply with the Terms and our Privacy Policy. If you do not agree to the Terms or our Privacy Policy, you are not authorized to access our software, use any of our software's services or place an order on our software.
                                    </p>

                                    <p>
                                        <b>2. Use of our Software</b><br />
                                        You agree to use our software for legitimate purposes and not for any illegal or unauthorized purpose, including without limitation, in violation of any intellectual property or privacy law. By agreeing to the Terms, you represent and warrant that you are at least the age of majority in your state or province of residence and are legally capable of entering into a binding contract.< br />
                                        <br />
                                        You agree to not use our software to conduct any activity that would constitute a civil or criminal offence or violate any law. You agree not to attempt to interfere with our software's network or security features or to gain unauthorized access to our systems.<br />
                                        <br />
                                        You agree to provide us with accurate personal information, such as your email address, mailing address and other contact details in order to complete your order or contact you as needed. You agree to promptly update your account and information. You authorize us to collect and use this information to contact you in accordance with our Privacy Policy.
                                    </p>

                                    <p>
                                        <b>3. General Conditions</b><br />
                                        We reserve the right to refuse service to anyone, at any time, for any reason. We reserve the right to make any modifications to the software, including terminating, changing, suspending or discontinuing any aspect of the software at any time, without notice. We may impose additional rules or limits on the use of our software. You agree to review the Terms regularly and your continued access or use of our software will mean that you agree to any changes.<br />
                                        <br />
                                        You agree that we will not be liable to you or any third party for any modification, suspension or discontinuance of our software or for any service, content, feature or product offered through our software.
                                    </p>

                                    <p>
                                        <b>4. Products or Services</b><br />
                                        All purchases through our software are subject to product availability. We may, in our sole discretion, limit or cancel the quantities offered on our software or limit the sales of our products or services to any person, household, geographic region or jurisdiction.<br />
                                        <br />
                                        Prices for our products are subject to change, without notice. Unless otherwise indicated, prices displayed on our software are quoted in Canadian dollars.<br />
                                        <br />
                                        We reserve the right, in our sole discretion, to refuse orders, including without limitation, orders that appear to be placed by distributors or resellers. If we believe that you have made a false or fraudulent order, we will be entitled to cancel the order and inform the relevant authorities.<br />
                                        <br />
                                        Our software is provided "as is", and we hold no responsibility to it's availability or stability. We reserve the right to withold service at any time.
                                    </p>

                                    <p>
                                        <b>5. Links to Third-Party Websites</b><br />
                                        Links from or to websites outside our software are meant for convenience only. We do not review, endorse, approve or control, and are not responsible for any sites linked from or to our software, the content of those sites, the third parties named therein, or their products and services. Linking to any other site is at your sole risk and we will not be responsible or liable for any damages in connection with linking. Links to downloadable software sites are for convenience only and we are not responsible or liable for any difficulties or consequences associated with downloading the software. Use of any downloaded software is governed by the terms of the license agreement, if any, which accompanies or is provided with the software.
                                    </p>

                                    <p>
                                        <b>6. Your Personal Information</b><br />
                                        Please see our Privacy Policy to learn about how we collect, use, and share your personal information.
                                    </p>

                                    <p>
                                        <b>7. Errors and Omissions</b><br />
                                        Please note that our software may contain typographical errors or inaccuracies and may not be complete or current. We reserve the right to correct any errors, inaccuracies or omissions and to change or update information at any time, without prior notice. Such errors, inaccuracies or omissions may relate to product description, pricing, promotion and availability and we reserve the right to cancel or refuse any order placed based on incorrect pricing or availability information, to the extent permitted by applicable law.<br />
                                        <br />
                                        We do not undertake to update, modify or clarify information on our software, except as required by law.
                                    </p>

                                    <p>
                                        <b>8. Disclaimer and Limitation of Liability</b><br />
                                        You assume all responsibility and risk with respect to your use of our software, which is provided “as is” without warranties, representations or conditions of any kind, either express or implied, with regard to information accessed from or via our software, including without limitation, all content and materials, and functions and services provided on our software, all of which are provided without warranty of any kind, including but not limited to warranties concerning the availability, accuracy, completeness or usefulness of content or information, uninterrupted access, and any warranties of title, non-infringement, merchantability or fitness for a particular purpose. We do not warrant that our software or its functioning or the content and material of the services made available thereby will be timely, secure, uninterrupted or error-free, that defects will be corrected, or that our software or the servers that make our software available are free of viruses or other harmful components.<br />
                                        <br />
                                        The use of our software is at your sole risk and you assume full responsibility for any costs associated with your use of our software. We will not be liable for any damages of any kind related to the use of our software.<br />
                                        <br />
                                        In no event will we, or our affiliates, our or their respective content or service providers, or any of our or their respective directors, officers, agents, contractors, suppliers or employees be liable to you for any direct, indirect, special, incidental, consequential, exemplary or punitive damages, losses or causes of action, or lost revenue, lost profits, lost business or sales, or any other type of damage, whether based in contract or tort (including negligence), strict liability or otherwise, arising from your use of, or the inability to use, or the performance of, our software or the content or material or functionality through our software, even if we are advised of the possibility of such damages.<br />
                                        <br />
                                        Certain jurisdictions do not allow limitation of liability or the exclusion or limitation of certain damages. In such jurisdictions, some or all of the above disclaimers, exclusions, or limitations, may not apply to you and our liability will be limited to the maximum extent permitted by law.
                                    </p>

                                    <p>
                                        <b>9. Indemnification</b><br />
                                        You agree to defend and indemnify us, and hold us harmless, and our respective directors, officers, agents, contractors, and employees against any losses, liabilities, claims, expenses (including legal fees) in any way arising from, related to or in connection with your use of our software, your violation of the Terms, or the posting or transmission of any materials on or through the software by you, including but not limited to, any third party claim that any information or materials provided by you infringe upon any third party proprietary rights.
                                    </p>

                                    <p>
                                        <b>10. Entire Agreement</b><br />
                                        The Terms and any documents expressly referred to in them represent the entire agreement between you and us in relation to the subject matter of the Terms and supersede any prior agreement, understanding or arrangement between you and us, whether oral or in writing. Both you and we acknowledge that, in entering into these Terms, neither you nor we have relied on any representation, undertaking or promise given by the other or implied from anything said or written between you and us prior to such Terms, except as expressly stated in the Terms.
                                    </p>

                                    <p>
                                        <b>11. Waiver</b><br />
                                        Our failure to exercise or enforce any right or provision of the Terms will not constitute a waiver of such right or provision. A waiver by us of any default will not constitute a waiver of any subsequent default. No waiver by us is effective unless it is communicated to you in writing.
                                    </p>

                                    <p>
                                        <b>12. Headings</b><br />
                                        Any headings and titles herein are for convenience only.
                                    </p>

                                    <p>
                                        <b>13. Severability</b><br />
                                        If any of the provisions of the Terms are determined by any competent authority to be invalid, unlawful or unenforceable, such provision will to that extent be severed from the remaining Terms, which will continue to be valid and enforceable to the fullest extent permitted by law.
                                    </p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </div>

        </Page >
    )
}

export default Terms
