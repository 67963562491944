import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

function Droplist(props) {
    const { name, options, value, onUpdate } = props

    function changed(e) {
        onUpdate(name, e.target.value)
    }

    console.log('value', value)

    return (
        <div>
            <Form.Select value={value} onChange={changed}>
                {
                    options.map((option, index) => {
                        return <option
                            key={index}
                            value={option.value}
                        >{option.lable}</option>
                    })
                }

            </Form.Select>
        </div>

    )
}


export default Droplist
