import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setFlash } from '../features/user/homeSlice'

function Flash() {
    const dispatch = useDispatch()
    const message = useSelector((state) => state.home.flash)

    useEffect(() => {
        if (message) {
            window.flashTimer = setTimeout(closeFlash, 8000)
        }
    })

    function closeFlash() {
        dispatch(setFlash(null))
    }

    function cleanMessage() {
        if (message.indexOf('<') < 0) return message
        return 'Something went wrong'
    }

    return (
        message && <div className='flash-banner' onClick={closeFlash}>
            {cleanMessage()}
        </div>
    )
}


export default Flash
