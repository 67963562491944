

function Wallpapers(props) {
    const { device, setImageKey } = props

    return (
        <div className="panel">
            <div className="config-row">
                <div className="config-col wallpaper-col">
                    <h5>Lock Screen</h5>
                    {
                        device.config.lockscreenWallpaper && <div className="wallpaper-img" onClick={() => { setImageKey('lockscreenWallpaper') }}>
                            <img className="wallpaper-thumb" src={device.config.lockscreenWallpaper} alt="Lock Screen Wallpaper" />
                        </div>
                    }
                </div>
                <div className="config-col wallpaper-col">
                    <h5>Home Screen</h5>
                    {
                        device.config.homescreenWallpaper && <div className="wallpaper-img" onClick={() => { setImageKey('homescreenWallpaper') }}>
                            <img src={device.config.homescreenWallpaper} alt="Home Screen Wallpaper" />
                        </div>
                    }
                </div>
            </div>

        </div>
    )
}


export default Wallpapers
