import React, { useEffect, useState } from 'react'

import { requestBrwoserData } from '../../lib/Api'
import Asimov from './asimov'
import pnn from './pnn'


function Index(props) {

    const device_id = props.match.params.device_id
    const uri = props.match.params.uri

    const [browser, setBrowser] = useState(false)

    useEffect(() => {
        if (!browser) {
            const request = requestBrwoserData(device_id)
            request.then((response) => {
                setBrowser(response.data)
            }).catch((error) => {
                if (error.response && error.response.data) {
                    console.log(error)
                }
            })
        }
    })



    const notFound = (<div className='browser container'>
        <h1>404 Not Found</h1>
    </div>)

    const pages = {
        'asimov.com': <Asimov browser={browser} />,
        'pnn.com': pnn,
    }

    if (!browser) return <div />

    if (!pages[uri]) return <Asimov device_id={device_id} />

    return pages[uri]
}

export default Index
