import React, { useState } from 'react'

function Checkbox(props) {
    const { onUpdate, obj, value, title } = props

    // const [value, setValue] = useState(false)

    function changed(e) {
        onUpdate(obj, e.target.checked)
    }

    return (
        <div className="input-container">
            <label>
                <input type="checkbox" onChange={changed} checked={value} />
                {title}
            </label>
        </div>
    )
}


export default Checkbox
