// Allowed Keys:
// MIN: int
// MAX: int
// MIN_LENGTH: int
// MAX_LENGTH: int
// PATTERN: regex
// BOOL: bool

const Validations = {
    user: {
        username: [
            { key: 'MIN_LENGTH', value: 4, message: "This needs to be at least 4 characters" },
            { key: 'MATCH_PATTERN', value: /(?=.*(fuck|cunt|shit|bitch|nigger|nigga|slut|twat|cock|dick))^(\w+)$/, message: "This is not an appropriate name" },
            { key: 'MATCH_PATTERN', value: /[^a-zA-Z\d]/, message: "Only letters and numbers allowed" },
        ],
        password: [
            { key: 'MIN_LENGTH', value: 8, message: "This needs to be at least 8 characters" },
        ],
        first_name: [
            { key: 'MIN_LENGTH', value: 3, message: "This needs to be at least 3 characters" },
            { key: 'MATCH_PATTERN', value: /(?=.*(fuck|cunt|shit|bitch|nigger|nigga|slut|twat|cock|dick))^(\w+)$/, message: "This is not an appropriate name" },
            { key: 'MATCH_PATTERN', value: /[^a-zA-Z\d]/, message: "Only letters and numbers allowed" },
        ],
        last_name: [
            { key: 'MIN_LENGTH', value: 3, message: "This needs to be at least 3 characters" },
            { key: 'MATCH_PATTERN', value: /(?=.*(fuck|cunt|shit|bitch|nigger|nigga|slut|twat|cock|dick))^(\w+)$/, message: "This is not an appropriate name" },
            { key: 'MATCH_PATTERN', value: /[^a-zA-Z\d]/, message: "Only letters and numbers allowed" },
        ],
    },
    device: {
        name: [
            { key: 'MIN_LENGTH', value: 3, message: "This needs to be at least 3 characters" },
            { key: 'MATCH_PATTERN', value: /[^a-zA-Z\d'-]/, message: "Only letters and numbers allowed" },
        ],
        description: [
            { key: 'MATCH_PATTERN', value: /(?=.*(fuck|cunt|shit|bitch|nigger|nigga|slut|twat|cock|dick))^(\w+)$/, message: "This is not an appropriate name" },
            { key: 'MATCH_PATTERN', value: /[^a-zA-Z\d]/, message: "Only letters and numbers allowed" },
        ]
    },

}

export default Validations
