import React from 'react'
import { useSelector } from 'react-redux'
import Flash from '../../Flash'
import { removeToken } from '../../../lib/Api'
import { Link } from "react-router-dom"

import { Navbar, Container, Nav, NavDropdown, Row, Col } from 'react-bootstrap'


function Page(props) {
    const { children, hero, devicePage } = props
    const user = useSelector((state) => state.home.user)

    function logout_user() {
        removeToken()
        window.location.replace('/')
    }

    return (
        <div>
            <Flash />
            <Navbar bg="dark" className="navbar-dark" expand="md">
                <Container>
                    <Navbar.Brand as={Link} to="/">Prop OS</Navbar.Brand>
                    {user.token && <Nav.Link as={Link} to='/dashboard'>Dashboard</Nav.Link>}
                    {user.token && user.isStaff && <Nav.Link as={Link} to='/buckles'>Buckles</Nav.Link>}
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        {
                            !user.token &&
                            <Nav className="ms-auto">
                                <Nav.Link as={Link} to="/how-it-works">How it Works</Nav.Link>
                                <Nav.Link as={Link} to="/support">Support</Nav.Link>
                                <Nav.Link as={Link} to="/login">Sign In</Nav.Link>
                                <Nav.Link as={Link} to="/sign-up">Sign Up</Nav.Link>
                            </Nav>
                        }

                        {
                            user.token &&
                            <Nav className="ms-auto">
                                <Nav.Link as={Link} to="#" onClick={logout_user}>Log Out</Nav.Link>
                            </Nav>
                        }
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <div className={` ${devicePage ? `device-page` : `page-container`} ${hero ? `hero` : ``}`}>
                {
                    hero &&
                    <div className="bg-image hero-panel">
                        <img className="hero-img" src="/images/neu_hero.png" alt="Open propOS app to make a phone a prop" />
                    </div>
                }
                <Row>
                    <Col>{children}</Col>
                </Row>
            </div>
            <div className='footer'>
                <div className='copyright'>Copyright &copy; James White 2023</div>
                <div className='links'><a href='/privacy'>Privacy Policy</a></div>
                <div className='links'><a href='/terms'>Terms &amp; Conditions</a></div>
                <div className='links'><a href='/support'>Help</a></div>
            </div>
        </div>
    )
}

export default Page
