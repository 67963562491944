import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, Link } from "react-router-dom"

import Page from '../components/layouts/Page'
import TextInput from '../components/inputs/Text'
import Validator from '../lib/Validator'
import { setProductions, setFlash } from '../features/user/homeSlice'
import { Button, Row, Col, Form } from 'react-bootstrap'
import { requestNewProduction } from '../lib/Api'
import { useTitle } from '../lib/Utils'


function NewProduction() {
    useTitle('New Production')
    const dispatch = useDispatch()
    const history = useHistory()

    const [values, setValues] = useState({
        name: '',
    })
    const [errors, setErrors] = useState({})

    const user = useSelector((state) => state.home.user)
    const validation_object = 'device'

    function handleChange(name, value) {
        const newValues = { ...values, [name]: value }
        setValues(newValues)
        setErrors(Validator(validation_object, newValues))
    }

    function submitForm(e) {
        e.preventDefault()
        const errors = Validator(validation_object, values)
        if (Object.keys(errors).length) return false

        const request = requestNewProduction(values)
        request.then((response) => {
            dispatch(setProductions(response.data))
            dispatch(setFlash(`Production created`))
            history.push('/dashboard')
        }).catch((error) => {
            dispatch(setFlash('Failed'))
        })

        return false
    }

    let isError = Object.keys(Validator(validation_object, values)).length > 0

    if (!user.token)
        return <Page><Link to="/login">Login Required</Link></Page>

    return (
        <Page>
            <Row>
                <Col md={{ span: 6, offset: 3 }} className="text-center">
                    <h2>Create a Production</h2>
                </Col>
            </Row>
            <Row className='mtop-30'>
                <Col md={{ span: 6, offset: 3 }}>
                    <Form onSubmit={submitForm}>
                        <Row>
                            <Col md={{ span: 12 }}>
                                <TextInput
                                    name="name"
                                    title="Name"
                                    onUpdate={handleChange}
                                    placeholder="The Wire"
                                    errors={errors}
                                    required={true}
                                    inputType='text'
                                />
                            </Col>
                        </Row>

                        <div className="d-grid gap-2 mtop-30">
                            <Button variant="primary" type="submit" disabled={isError}>
                                Submit
                            </Button>
                        </div>

                    </Form>
                </Col>
            </Row>
        </Page>
    )
}

export default NewProduction
