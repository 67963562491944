function Wifi(props) {
    const { color, cellsignal } = props

    const nilColor = 'rgba(100,100,100,0.4)'

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 455.6562 369.2062" {...props}>
            <rect y={cellsignal > 10 ? "158.914" : "237.82"} width="78.9233" height={cellsignal > 10 ? "158.914" : "80"} fill={cellsignal > 10 ? color : nilColor} />
            <rect x="125.5776" y={cellsignal > 25 ? "105.0266" : "237.82"} width="78.9233" height={cellsignal > 25 ? "212.8014" : "80"} fill={cellsignal > 25 ? color : nilColor} />
            <rect x="251.1552" y={cellsignal > 50 ? "53.1602" : "237.82"} width="78.9233" height={cellsignal > 50 ? "264.6678" : "80"} fill={cellsignal > 50 ? color : nilColor} />
            <rect x="376.7328" y={cellsignal > 75 ? "0" : "237.82"} width="78.9233" height={cellsignal > 75 ? "317.8279" : "80"} fill={cellsignal > 75 ? color : nilColor} />
        </svg>
    )
}


export default Wifi
