import { useState } from 'react'
import Carousel from 'react-bootstrap/Carousel'


import WifiSignal from '../svgs/wifiSignal'
import CellSignal from '../svgs/cellSignal'

import { changeAlpha, rgbToGrayscale } from '../../lib/Utils'

function Preview(props) {
    const { device, } = props
    const [view, setView] = useState(1)

    function cycleView() {
        if (view > 1) return setView(0)
        setView(view + 1)
    }

    function formatTime(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes
        return strTime;
    }

    function formatDate(format, date) {
        if (format === 'dd/mm/yyyy') {
            const yyyy = date.getFullYear();
            let mm = date.getMonth() + 1
            let dd = date.getDate();

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;

            return dd + '/' + mm + '/' + yyyy;
        }

        if (format === 'long') {
            const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
            return [
                days[date.getDay()],
                date.toLocaleDateString('en-US', { day: 'numeric' }),
                date.toLocaleDateString('en-US', { month: 'long' })
            ].join(' ')
        }
    }

    const iconUrl = (app) => {
        const slug = app.slug ? app.slug : app.name.toLowerCase().replace(' ', '_')
        return `/apps/${slug}.png`
    }

    function recentMsg(thread) {
        if (thread.length < 1) return ''
        let text = thread[thread.length - 1].text
        const limit = 80

        if (text && text.length > limit) {
            text = text.substr(0, limit)
            text = text.substr(0, text.lastIndexOf(" ")) + '...'
        }
        return text
    }

    function statusBar(showClock = true) {
        const fillColor = device.config.batteryLevel < 20 ? 'red' : device.config.theme.color
        return (
            <div className="statusbar">
                {showClock && <div className="clock">{formatTime(new Date(device.config.date))}</div>}

                <WifiSignal color={device.config.theme.color} wifisignal={device.config.wifiSignal} className="wifi" />
                <CellSignal color={device.config.theme.color} cellsignal={device.config.cellSignal} className="cell" />

                <div className="battery" style={{ borderColor: device.config.theme.color }}>
                    <div className="fill" style={{ backgroundColor: fillColor, width: device.config.batteryLevel + '%' }}></div>
                    <div className="nipple" style={{ backgroundColor: device.config.theme.color }}></div>
                </div>

            </div>
        )
    }

    function homeAppps() {
        return (
            <div className="apps-container">
                {
                    device.config.homeApps[0].map((app, index) => <div className="app-tile" key={`preview_app_${index}`}>
                        <img className='app-icon' alt={app.name} src={iconUrl(app)} />
                        <div className="app-title">{app.name}</div>
                        {app.badge > 0 && <div className="app-badge" />}
                    </div>
                    )
                }
            </div>
        )
    }

    function dockApps() {
        return (
            <div className="dock">
                {
                    device.config.dockApps.map((app, index) => <div className="dock-tile" key={`dock_app_${index}`}>
                        <img className='app-icon' alt={app.name} src={iconUrl(app)} />
                        {app.badge > 0 && <div className="app-badge" />}
                    </div>
                    )
                }
            </div>
        )
    }

    function broken() {
        if (!device.config.broken) return <div></div>
        return <div className="broken">
            <img src="/preview/break_01.png" />
        </div>
    }

    if (!device || !device.config) return <div></div>


    return (
        <Carousel fade interval={null} indicators={false} controls={false} activeIndex={view}>

            {/* Lock screen */}
            <Carousel.Item >
                <div className="screen-preview lockscreen" style={{ color: device.config.theme.color }} onClick={cycleView}>
                    <div className="background-holder" style={{ backgroundImage: 'url(' + device.config.lockscreenWallpaper + ')' }} />
                    {statusBar(false)}

                    <div className="date">{formatDate('long', new Date(device.config.date))}</div>
                    <div className="clock">{formatTime(new Date(device.config.date))}</div>
                    {broken()}
                </div >
            </Carousel.Item>

            {/* Home screen */}
            <Carousel.Item >
                <div className="screen-preview homescreen" style={{ color: device.config.theme.color }} onClick={cycleView}>
                    <div className="background-holder" style={{ backgroundImage: 'url(' + device.config.homescreenWallpaper + ')' }} />
                    <div className="background-matte" style={{ backgroundColor: changeAlpha(rgbToGrayscale(device.config.theme.background), 0.2) }} />

                    {statusBar()}

                    {homeAppps()}

                    {dockApps()}

                    {broken()}
                </div >

            </Carousel.Item>

            {/*  App screen */}
            <Carousel.Item>
                <div className="screen-preview homescreen" onClick={cycleView} style={{ color: device.config.theme.color, backgroundColor: device.config.theme.background }}>
                    {statusBar()}
                    <div className="in-app-title" style={{ borderBottomColor: device.config.theme.color }}>Messages</div>
                    {
                        device.config.messages.map((msgThread, index) => {
                            return <div key={`msg_preview_${index}`} className="msg-item" style={{ borderColor: device.config.theme.color }}>
                                <img className="icon avatar" alt="avatar" src={msgThread.image ? msgThread.image : '/unknown_contact.png'} />
                                <div className='name-col'>
                                    <div className='name'>{msgThread.name}</div>
                                    <div className='recent-msg'>{recentMsg(msgThread.thread)}</div>
                                </div>
                                <object data="/images/right-chev.svg" type="image/svg+xml" className="right-chev">Right Arrow</object>
                            </div>
                        })
                    }
                    {broken()}
                </div >
            </Carousel.Item>
        </Carousel>
    )
}


export default Preview
