import React from 'react'
import { Row, Card, Col } from 'react-bootstrap'

import Page from '../components/layouts/Page'
import { useTitle } from '../lib/Utils'


function Support() {
    useTitle('Support')
    return (
        <Page>
            <div className='hero-page-content'>
                <Row className="mx-0 text-center justify-content-md-center">
                    <h1>Support</h1>
                </Row>

                <Row className="mx-0 justify-content-md-center mt-3 show-links">
                    <Col sm={8}>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    <h3>Setting up the app</h3>
                                    <p>
                                        <b>How does it work?</b><br />
                                        Great question! there's a page all about it: <a href="/how-it-works">How It Works</a>.
                                    </p>
                                    <p>
                                        <b>The PIN doesn't work</b><br />
                                        Make sure the phone is connected to the internet. Make sure the PIN is correct. Restart the app and try again (have you tried turning it off and turning it on again?). If you're still having troubles, email <a href="mailto:help@prop-os.com">help@prop-os.com</a>.
                                    </p>
                                    <p>
                                        <b>Why am I seeing a watermark?</b><br />
                                        To remove the watermark please contact <a href="mailto:help@prop-os.com">help@prop-os.com</a> - we just need to confirm that you are working in the film industry and not trying to use the app to prank someone.
                                    </p>

                                    <p>
                                        <b>Can I link my phone to a different Device?</b><br />
                                        Tapping "Reset" under settings will unlink the app from any device, you can then enter a different PIN to link it again.
                                    </p>

                                    <p>
                                        <b>How can I stop actors leaving the app?</b><br />
                                        Closing the app would expose the primary operating system and potentially ruin a scene. Once the app is open, we recommend putting iPhones into Guided Access mode and use the "pinning" feature on Android phones to limit this.<br />
                                        <br />
                                        iPhone Guided Access is relatively simple to setup, details can be found here: <br />
                                        <a href="https://support.apple.com/en-ca/guide/iphone/iph7fad0d10/ios" target="_blank">https://support.apple.com/en-ca/guide/iphone/iph7fad0d10/ios</a>
                                        <br />
                                        <br />
                                        Android Devices is also straight forward, details can be found here:<br />
                                        <a href="https://support.google.com/android/answer/9455138" target="_blank">https://support.google.com/android/answer/9455138</a>
                                    </p>

                                    <hr />
                                    <h3>Using the app</h3>
                                    <p>
                                        <b>How can i get back to the home screen?</b><br />
                                        Tap the very bottom of the screen. If you're using Guided Access on iOS (recommended) then you can swipe up from the bottom as you would do in iOS.<br />
                                        <br />
                                        On Android tap the larger home button at the bottom of the screen.
                                    </p>

                                    <p>
                                        <b>How do I show notifications in the app?</b><br />
                                        Tap the status bar at the top of the screen (where the clock, signal and battery indicators are).<br />
                                        Swipe notifications up to dismiss.<br />
                                        <br />
                                        You can also force notifications to popup on the phone from the control panel. Remember to sync any new notifications before attempting to show them on a phone.
                                    </p>

                                    <p>
                                        <b>Why do some of the apps on the home screen not open?</b><br />
                                        The majority of the app icons available to put on home screens are there to allow you to make a device feel busy or used. They are non-functional.<br />
                                        <br />
                                        Core app have functionality, including phone, messages, mail, contacts, browser, and photos <br />
                                        <br />
                                        If you would like a specific app to be functional, or would like an app type added, please contact <a href="mailto:help@prop-os.com">help@prop-os.com</a>.
                                    </p>

                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </div>

        </Page >
    )
}

export default Support
