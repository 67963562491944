import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from "react-router-dom"
import { setFlash, setDeviceConfig, setUploads, setOnCall, setSynced, setSaved } from '../features/user/homeSlice'
import { Accordion } from 'react-bootstrap'

import { requestUpload, requestSaveConfig } from '../lib/Api'
import Socket from '../lib/Socket'
import Flash from '../components/Flash'

import Page from '../components/layouts/Page'
import Handsets from '../components/devicePanels/handsets'
import Preview from '../components/devicePanels/preview'
import Settings from '../components/devicePanels/settings'
import Wallpapers from '../components/devicePanels/wallpapers'
import Messages from '../components/devicePanels/messages'
import Apps from '../components/devicePanels/apps'
import Contacts from '../components/devicePanels/contacts'
import Phone from '../components/devicePanels/phone'
import Photos from '../components/devicePanels/photos'
import Browser from '../components/devicePanels/browser'
import Notifications from '../components/devicePanels/notifications'
import Mail from '../components/devicePanels/mail'
import Notes from '../components/devicePanels/notes'

window.app = {
    keys: [],
    history: { pointer: 0, states: [] }
}

function Device(props) {
    const config_version = 0.2
    const dispatch = useDispatch()
    const user = useSelector((state) => state.home.user)
    const onCall = useSelector((state) => state.home.onCall)
    const synced = useSelector((state) => state.home.synced)
    const saved = useSelector((state) => state.home.saved)

    const [production, setProduction] = useState(false)
    const [imageKey, setImageKey] = useState(false)
    const [advanced, setAdvanced] = useState(false)
    const [advancedParses, setAdvancedParses] = useState(true)
    const [uploadInProgress, setUploadInProgress] = useState(false)

    const ftuWallpapers = [

        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/8190572251.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/7838328376.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/4236212429.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/5243184893.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/5520445348.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/3732558152.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/7612738083.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/4121546247.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/1437890398.jpg' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/6725456346.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/7014542721.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/7952275839.png' },

        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/1905317455.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/3304995566.jpg' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/3852724111.jpg' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/1022157439.png' },
    ]
    const ftuPhotos = [

        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/2508086993.jpg' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/4323089492.jpg' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/9271717352.jpg' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/7508167952.jpg' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/9155465722.jpg' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/4459641173.jpg' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/4433440891.jpg' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/2518796730.jpg' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/5931329128.jpg' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/3454514025.jpg' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/9193453606.jpg' },

    ]

    const ftuAvatars = [

        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/6913264198.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/7416122617.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/6485203416.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/1843024108.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/7128301662.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/7559679791.png' },

        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/1394436743.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/1989183138.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/2031344095.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/8999791369.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/7353224085.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/8265739707.png' },

        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/8196763952.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/3688204180.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/2483003513.png' },
        { file: 'https://prop-os-prod.s3.amazonaws.com/production_uploads/8951764156.png' },


    ]


    //--------

    const [device, _setDevice] = useState(false)
    const deviceRef = React.useRef(device)
    const setDevice = data => {
        if (data === deviceRef.current) return console.log('no changes - not saving')
        if (deviceRef.current) {
            dispatch(setSynced(false))
            dispatch(setSaved(false))
        }
        deviceRef.current = data
        _setDevice(data)
    }

    // keyboard shortcuts
    const mac = navigator.userAgent.indexOf('Mac OS X') > -1

    // get id from url params
    const production_id = props.match.params.production_id
    const device_id = props.match.params.device_id

    useEffect(() => {
        if (user.token == null) {
            return
        }

        // get device from profile
        const prod = user.profile.productions.filter(prod => { return prod.external_id === production_id })[0]
        const _device = prod.devices.filter(dev => { return dev.external_id === device_id })[0]

        setProduction(prod)
        setDevice(_device)
        document.title = `PropOS - ${prod.name} - ${_device.name}`

        if (!advanced) {
            setAdvanced(JSON.stringify(_device.config, null, 4))
        }

        if (window.app.history.states.length < 1) window.app.history.states.push(_device.config)

        // open socket
        if (device_id && !window.socket) {
            window.socket = Socket.init(dispatch, production_id, device_id)
            window.socket.connect(device_id)
        }

    })
    // fuck you bitching about the dep tree react. get all the way fucked.

    // mount only
    useEffect(() => {
        setSaved(true)
        document.addEventListener('keydown', downKey)
        document.addEventListener('keyup', upKey)
        window.app = {
            keys: [],
            history: { pointer: 0, states: [] }
        }
    }, []);

    // unmount
    useEffect(() => () => {
        document.removeEventListener('keydown', downKey)
        document.removeEventListener('keyup', upKey)
        window.socket.disconnect()
        window.socket = 0
        document.title = 'PropOS'
    }, [])

    const downKey = (e) => {
        if (['Meta', 'Shift'].includes(e.key) && !window.app.keys.includes(e.key)) window.app.keys.push(e.key)

        if (e.key === 's' && window.app.keys.includes('Meta') && window.app.keys.includes('Shift')) {
            e.preventDefault()
            sendConfig(deviceRef.current)
        }

        if (e.key === 's' && window.app.keys.includes('Meta') && !window.app.keys.includes('Shift')) {
            e.preventDefault()
            saveToServer(false, deviceRef.current)
        }

        if (e.key === 'z' && window.app.keys.includes('Meta')) {
            e.preventDefault()
            undo()
        }
        if (e.key === 'z' && window.app.keys.includes('Meta') && window.app.keys.includes('Shift')) {
            e.preventDefault()
            redo()
        }

        if (e.key === 'e' && window.app.keys.includes('Meta')) {
            e.preventDefault()
            endCall()
        }
    }

    const upKey = (e) => {
        window.app.keys = window.app.keys.filter(function (k) { return k !== e.key })
    }

    const exit = () => {
        if (!saved && !window.confirm('You have unsaved Changed - Exit anyway?')) return
        window.location.replace(`/production/${production_id}`)
    }

    const save = (config, noHistory = false) => {
        dispatch(setDeviceConfig({ production_id: production_id, device_id: device_id, config: config }))
        if (!noHistory) {
            if (window.app.history.pointer > 1) {
                forkHistory()
            }
            addToHistory(config)
        }
    }

    const forkHistory = () => {
        window.app.history.states.splice(-window.app.history.pointer)
        window.app.history.pointer = 0
    }

    const addToHistory = (config) => {
        window.app.history.states.push(config)
        if (window.app.history.states.length > 100) {
            window.app.history.states.shift()
        }
    }

    const undo = () => {
        const newIndex = window.app.history.states.length - (window.app.history.pointer + 1)
        if (!window.app.history.states[newIndex - 1]) return
        window.app.history.pointer = window.app.history.pointer + 1
        save(window.app.history.states[newIndex - 1], true)
    }

    const redo = () => {
        const newIndex = window.app.history.states.length - (window.app.history.pointer - 1)
        if (!window.app.history.states[newIndex - 1]) return
        window.app.history.pointer = window.app.history.pointer - 1
        save(window.app.history.states[newIndex - 1], true)
    }


    const saveToServer = (e, _device) => {
        let id, config
        if (_device) {
            id = _device.external_id
            config = _device.config
        } else {
            id = device_id
            config = device.config
        }
        const request = requestSaveConfig(id, config)
        request.then((response) => {
            dispatch(setSaved(true))
        }).catch((error) => {
            console.log(error)
        })
    }

    const startCall = (callerId, image) => {
        window.socket.sendEvent({ event: 'incomingCall', callerId: callerId, image: image })
        dispatch(setOnCall(true))
    }

    const endCall = () => {
        window.socket.sendEvent(null)
        window.socket.sendEvent({ event: 'terminateCall' })
        dispatch(setOnCall(false))
    }

    // image management

    // why aren't you sending an object ya fucken derelict?
    const setImage = (url) => {
        if (imageKey.includes(':')) {
            const [key, index, subKey] = imageKey.split(':')
            if (key === 'messageAttachment') { return messageAttachment(parseInt(index), url) }
            if (index === 'append') { return appendImage(key, url.split('?')[0]) }

            const newOjb = { ...device.config[key][index], [subKey]: url.split('?')[0] }
            save({ ...device.config, [key]: device.config[key].map((existing, i) => i === parseInt(index) ? newOjb : existing) })
        } else {
            save({ ...device.config, [imageKey]: url.split('?')[0] })
        }

        setImageKey(false)
    }

    const appendImage = (key, url) => {
        save({ ...device.config, [key]: [...device.config[key], url] })
        setImageKey(false)
    }

    const messageAttachment = (index, url) => {

        const newThread = [...device.config.messages[index].thread, { "new": true, "text": `attachment::${url.split('?')[0]}`, "inbound": true }]

        const newConfig = {
            ...device.config, messages: device.config.messages.map((existingMsg, i) => i === index ? { ...device.config.messages[index], thread: newThread } : existingMsg)
        }

        save(newConfig)
        setImageKey(false)
    }

    const uploadFile = (e) => {
        setUploadInProgress(e.target.files[0].name)
        const request = requestUpload(e.target.files[0], production.external_id)
        request.then((response) => {
            // dispatch(setProductions(response.data))
            const prod = response.data.filter(prod => { return prod.external_id === production.external_id })[0]
            dispatch(setUploads({ production_id: prod.external_id, uploads: prod.uploads }))
            dispatch(setFlash(`File Uploaded`))
            setUploadInProgress(false)
            //setProduction(prod)
        }).catch((error) => {
            dispatch(setFlash('Failed'))
        })
    }

    const updateAdvanced = (str) => {
        setAdvanced(str)
        let strGood = true
        try {
            JSON.parse(str)
        } catch {
            strGood = false
        }
        setAdvancedParses(strGood)
    }

    // SAVE

    const sendConfig = (_device) => {
        let config = { ...device.config }
        if (_device && _device.config) {
            config = { ..._device.config }
        }
        config.config_version = config_version
        window.socket.sendConfig(JSON.stringify(config))
    }


    // RENDER

    const galleryAccordion = (galName, arr, showUpload) => {
        return <Accordion.Item eventKey={galName}>
            <Accordion.Header>{galName} ({arr.length})</Accordion.Header>
            <Accordion.Body>
                <div className="upload-container">
                    {
                        arr.map((item, index) => {
                            return <div onClick={() => setImage(item.file)} className="upload-img" key={`${galName}_${index}`}>
                                <img src={item.file.split('?')[0]} alt={item.name} />
                            </div>
                        })
                    }
                    {
                        showUpload && uploadInProgress && <div className="upload-img">
                            <img src='/loading.gif' alt="loading" />
                            <h5>{uploadInProgress}</h5>
                        </div>
                    }


                    {
                        showUpload && !uploadInProgress && <div className="upload-btn-holder">
                            <span />
                            <input className="upload-btn" type="file" name="upload" accept="image/jpeg,image/png,image/gif" onChange={(e) => { uploadFile(e) }} />
                        </div>
                    }
                </div>
            </Accordion.Body>
        </Accordion.Item>
    }

    if (!user.token)
        return <Page><Link to="/login">Login Required</Link></Page>

    if (!device) return <div></div>

    return (
        <div>
            <div className="device-nav">
                <div className="btn danger" onClick={exit} title="Exit">Exit</div>

                <div className="btn" onClick={undo} title={`Undo (${mac ? '⌘' : 'CRTL'} Z)`}>Undo</div>
                <div className="btn" onClick={redo} title={`Redo (${mac ? '⌘' : 'CRTL'} ⇧ Z)`}>Redo</div>

                <div className="right-panel">
                    {onCall &&
                        <div className=" btn end-call-btn"><img className="end-call" alt="end call" src="/call_reject.png" onClick={() => { endCall() }} title={`End Call (${mac ? '⌘' : 'CRTL'} E)`} /></div>
                    }
                    <div className={`btn save-btn ${saved ? 'saved' : 'unsaved'}`} onClick={saveToServer} title={`Save (${mac ? '⌘' : 'CRTL'} S)`}>
                        <span>Save</span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110.9141 110.9141">
                            <circle className="svg-tick" cx="55.457" cy="55.457" r="52.457" />
                            <polyline className="svg-tick" points="80.528 35.657 49.414 75.257 30.386 59.828" />
                        </svg>
                    </div>
                    <div className={`btn cta sync-btn ${synced ? 'synced' : 'unsynced'}`} onClick={sendConfig} title={`Save and Sync (${mac ? '⌘' : 'CRTL'} ⇧ S)`}>
                        <span>Sync</span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110.9141 110.9141">
                            <circle className="svg-tick" cx="55.457" cy="55.457" r="52.457" />
                            <polyline className="svg-tick" points="80.528 35.657 49.414 75.257 30.386 59.828" />
                        </svg>
                    </div>
                </div>
            </div>
            <div className="device-page">


                <div className="device-holder">
                    <h4>{production.name} - {device.name}</h4>

                    <Accordion>

                        <Accordion.Item eventKey={'handsets'} >
                            <Accordion.Header>Link to Phone</Accordion.Header>
                            <Accordion.Body>
                                <Handsets device={device} />
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey={'settings'} >
                            <Accordion.Header>Settings</Accordion.Header>
                            <Accordion.Body>
                                <Settings device={device} save={save} />
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey={'wallpapers'} >
                            <Accordion.Header>Wallpapers</Accordion.Header>
                            <Accordion.Body>
                                <Wallpapers device={device} setImageKey={setImageKey} />
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey={'notifications'} >
                            <Accordion.Header>Notifications</Accordion.Header>
                            <Accordion.Body>
                                <Notifications device={device} save={save} />
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey={'apps'} >
                            <Accordion.Header>Home screen</Accordion.Header>
                            <Accordion.Body>
                                <Apps device={device} save={save} />
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey={'messages'} >
                            <Accordion.Header>Messages</Accordion.Header>
                            <Accordion.Body>
                                <Messages device={device} save={save} setImageKey={setImageKey} />
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey={'contacts'} >
                            <Accordion.Header>Contacts</Accordion.Header>
                            <Accordion.Body>
                                <Contacts device={device} save={save} setImageKey={setImageKey} startCall={startCall} />
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey={'phone'} >
                            <Accordion.Header>Phone</Accordion.Header>
                            <Accordion.Body>
                                <Phone device={device} save={save} setImageKey={setImageKey} startCall={startCall} />
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey={'photos'} >
                            <Accordion.Header>Photos</Accordion.Header>
                            <Accordion.Body>
                                <Photos device={device} save={save} setImageKey={setImageKey} />
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey={'mail'} >
                            <Accordion.Header>Mail</Accordion.Header>
                            <Accordion.Body>
                                <Mail device={device} save={save} />
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey={'notes'} >
                            <Accordion.Header>Notes</Accordion.Header>
                            <Accordion.Body>
                                <Notes device={device} save={save} />
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey={'browser'} >
                            <Accordion.Header>Browser</Accordion.Header>
                            <Accordion.Body>
                                <Browser device={device} save={save} />
                            </Accordion.Body>
                        </Accordion.Item>

                        {user.isStaff && <Accordion.Item eventKey={'Advanced'} >
                            <Accordion.Header>Advanced</Accordion.Header>
                            <Accordion.Body>
                                <div className="notice">Config Version: {config_version}</div>
                                <textarea
                                    value={advanced}
                                    onChange={(e) => { updateAdvanced(e.target.value) }}
                                    onBlur={(e) => { if (advancedParses) save(JSON.parse(advanced)) }}
                                    style={{ width: '100%', height: 700, backgroundColor: advancedParses ? '#fff' : '#f9e3e3' }}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                        }

                    </Accordion>

                    <div className="preview-holder">
                        <Preview device={device} screen="homeScreen" />
                    </div>
                </div>

                {/* ------ MODALS ------- */}

                {
                    imageKey && <div>
                        <div className="matte" onClick={() => { setImageKey(false) }}></div>
                        <div className="modal-container">
                            <h4>Image Library</h4>
                            <Accordion defaultActiveKey="Your Uploaded Images">

                                {galleryAccordion('Free To Use Wallpapers', ftuWallpapers, false)}
                                {galleryAccordion('Free To Use Photos', ftuPhotos, false)}
                                {galleryAccordion('Free To Use Avatars', ftuAvatars, false)}
                                {galleryAccordion('Your Uploaded Images', production.uploads, true)}

                                {/* <Accordion.Item eventKey={'uploads'}>
                                    <Accordion.Header>Your Uploaded Images ({production.uploads.length})</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="upload-container">
                                            {
                                                production.uploads.map((upload, index) => {
                                                    return <div onClick={() => setImage(upload.file)} className="upload-img" key={`img_lib_${index}`}>
                                                        <img src={upload.file.split('?')[0]} alt={upload.name} />
                                                    </div>
                                                })
                                            }

                                            {
                                                uploadInProgress && <div className="upload-img">
                                                    <img src='/loading.gif' alt="loading" />
                                                    <h5>{uploadInProgress}</h5>
                                                </div>
                                            }


                                            {
                                                !uploadInProgress && <div className="upload-btn-holder">
                                                    <span />
                                                    <input className="upload-btn" type="file" name="upload" accept="image/jpeg,image/png,image/gif" onChange={(e) => { uploadFile(e) }} />
                                                </div>
                                            }
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item> */}
                            </Accordion>

                        </div>
                    </div>

                }
            </div >
            <Flash />
        </div>
    )
}

export default Device
