import { useState } from 'react'

function Photos(props) {
    const { device, save, setImageKey } = props
    const [dragging, setDrag] = useState({})

    const dragStart = (e, index) => {
        e.dataTransfer.setDragImage(document.createElement('span'), 0, 0)
        setDrag({ index: index })
    }

    const dragEnter = (e, target) => {
        if (dragging.index === undefined || dragging.index === target) return

        const photos = [...device.config.photos]
        photos.splice(dragging.index, 1)
        photos.splice(target, 0, device.config.photos[dragging.index])
        save({ ...device.config, photos: photos })

        setDrag({ index: target })
    }

    const dragEnd = () => {
        setDrag({})
    }

    const delPhoto = (index) => {
        const photos = [...device.config.photos]
        photos.splice(index, 1)
        save({ ...device.config, photos: photos })
    }

    if (!device) return <div></div>

    return (
        <div className="panel">
            <div className="config-row">
                <div className="config-col photo-display">
                    <h5>Photos</h5>
                    <p>
                        These are the photos that will be displayed in the Photos app.
                    </p>

                    {
                        device.config.photos.map((photo, index) => {
                            return <div
                                className={`app-item ${index === dragging.index ? 'active-app-item' : ''}`}
                                key={`photo_${index}`}
                                onDragEnter={(e) => dragEnter(e, index)}
                                draggable
                                onClick={(e) => { e.stopPropagation() }}
                                onDragStart={(e) => dragStart(e, index)}
                                onDragEnd={dragEnd}
                            >
                                <img className="photo" alt={photo} src={photo} />
                                <div className="row-del" onClick={() => { delPhoto(index) }}><div className="iron-cross" /></div>
                            </div>
                        })
                    }

                    <div className="app-item add-photo" onClick={() => { setImageKey(`photos:append`) }}><span />Add</div>

                </div>
            </div>

        </div>
    )
}


export default Photos
