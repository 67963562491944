import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getToken, requestTokenUser } from './lib/Api'
import { login } from './features/user/homeSlice'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Index from './pages/index'
import Signup from './pages/signup'
import Login from './pages/login'
import Dashboard from './pages/dashboard'
import NewProduction from './pages/newProduction'
import Production from './pages/production'
import NewDevice from './pages/newDevice'
import Device from './pages/device'

import HowItWorks from './pages/howItWorks'
import Terms from './pages/terms'
import Privacy from './pages/privacy'
import Support from './pages/support'
import Download from './pages/download'

import AdminIndex from './pages/admin/index'


// browser pages
import Browser from './pages/browserPages/index'




function App() {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.home.user)


    useEffect(() => {
        // log user in by token
        const token = getToken()
        if (token !== undefined && !user.token) {
            const request = requestTokenUser(token)
            request.then((response) => {
                dispatch(login({ ...response.data.user, token }))
            }).catch((error) => {
                // removeToken()
                if (error.response && error.response.data) {
                    console.log(error)
                }
            })
        }

    })


    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Index} />
                <Route exact path="/how-it-works" component={HowItWorks} />
                <Route exact path="/terms" component={Terms} />
                <Route exact path="/privacy" component={Privacy} />
                <Route exact path="/support" component={Support} />
                <Route exact path="/download" component={Download} />

                <Route exact path="/sign-up" component={Signup} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/new-production" component={NewProduction} />
                <Route exact path="/production/:production_id" component={Production} />
                <Route exact path="/production/:production_id/new-device" component={NewDevice} />
                <Route exact path="/production/:production_id/device/:device_id" component={Device} />


                {/* <Route exact path="/browser-pages/:device_id" component={SearchPage} /> */}
                <Route exact path="/browser-pages/:device_id/:uri" component={Browser} />


                <Route exact path="/buckles/" component={AdminIndex} />


                <Route exact path="*" component={Index} />
            </Switch>
        </Router>
    )
}

export default App
