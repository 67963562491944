import { createSlice } from '@reduxjs/toolkit'
import { setToken } from '../../lib/Api'

const initialState = {
    user: {
        token: null,
        name: null,
        isStaff: false,
        profile: {
            relationships: []
        }
    },

    flash: null,
    synced: false,
    saved: true,
    onCall: false
}

function loginReducer(state, action) {
    setToken(action.payload.token)
    state.user = {
        email: action.payload.username,
        token: action.payload.token,
        profile: action.payload.profile,
        isStaff: action.payload.is_staff
    }
}

function setFlashReducer(state, action) {
    state.flash = action.payload
}

function setProductionsReducer(state, action) {
    state.user.profile.productions = action.payload
}

function setDevicesReducer(state, action) {
    state.user.profile.devices = action.payload
}

function setConfigReducer(state, action) {
    const production_index = state.user.profile.productions.findIndex(prod => prod.external_id === action.payload.production_id)
    const device_id = state.user.profile.productions[production_index].devices.findIndex(device => device.external_id === action.payload.device_id)
    state.user.profile.productions[production_index].devices[device_id].config = action.payload.config
}

function setUploadsReducer(state, action) {
    const production_index = state.user.profile.productions.findIndex(prod => prod.external_id === action.payload.production_id)
    state.user.profile.productions[production_index].uploads = action.payload.uploads
}

function addMsgReducer(state, action) {
    console.log('here', action.payload)
    const production_index = state.user.profile.productions.findIndex(prod => prod.external_id === action.payload.production_id)
    const device_index = state.user.profile.productions[production_index].devices.findIndex(device => device.external_id === action.payload.device_id)

    const newThread = [
        ...state.user.profile.productions[production_index].devices[device_index].config.messages[action.payload.msg_id].thread,
        { "new": false, "text": action.payload.text, "inbound": false }
    ]
    state.user.profile.productions[production_index].devices[device_index].config.messages[action.payload.msg_id].thread = newThread
}

function setOnCallReducer(state, action) {
    state.onCall = action.payload
}

function setSyncedReducer(state, action) {
    state.synced = action.payload
}

function setSavedReducer(state, action) {
    state.saved = action.payload
}

export const homeSlice = createSlice({
    name: 'home',

    initialState,

    reducers: {
        login: loginReducer,
        setFlash: setFlashReducer,
        setProductions: setProductionsReducer,
        setDevices: setDevicesReducer,
        setDeviceConfig: setConfigReducer,
        setUploads: setUploadsReducer,
        addMsg: addMsgReducer,
        setOnCall: setOnCallReducer,
        setSynced: setSyncedReducer,
        setSaved: setSavedReducer
    }
})

export const {
    login,
    setFlash,
    setDevices,
    setProductions,
    setDeviceConfig,
    setUploads,
    addMsg,
    setOnCall,
    setSynced,
    setSaved
} = homeSlice.actions

export default homeSlice.reducer
