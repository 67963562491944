import { useState } from 'react'

function Notes(props) {

    const [showPanels, setShowPanels] = useState([])
    const [dragIndex, setDragIndex] = useState(false)
    const { device, save } = props

    const dragStart = (e, index) => {
        e.dataTransfer.setDragImage(document.createElement('span'), 0, 0)
        setDragIndex(index)
    }

    const dragEnter = (e, target) => {
        if (dragIndex === target) return

        const copy = [...device.config.notes]
        copy.splice(dragIndex, 1)
        copy.splice(target, 0, device.config.notes[dragIndex])
        save({ ...device.config, notes: copy })

        // save the new index for future dragging
        setDragIndex(target)
    }

    const dragEnd = () => {
        setDragIndex(false)
    }

    function createNote() {
        save({ ...device.config, notes: [...device.config.notes, 'New Note'] })
    }

    function delNote(index) {
        if (!window.confirm('Are you sure?')) return
        const notes = [...device.config.notes]
        notes.splice(index, 1)
        save({ ...device.config, notes: notes })
    }


    function updateNote(i, value) {
        save({
            ...device.config, notes: device.config.notes.map((note, ii) => i == ii ? value : note)
        })
    }

    function togglePanel(index) {
        if (showPanels.includes(index)) {
            setShowPanels([...showPanels].filter(function (e) { return e !== index }))
        } else {
            setShowPanels([...showPanels, index])
        }
    }




    if (!device) return <div></div>

    return (
        <div>
            <div className="panel">
                <div className="config-row">
                    <div className="config-col">
                        <p>These are the notes that will show in the notes app.</p>

                        {
                            device.config.notes &&
                            device.config.notes.map((note, index) => {
                                const title = note.split('\n')[0]
                                return <div key={`notes_${index}`}>
                                    <div className={`list-item ${index === dragIndex ? 'active-app-item' : ''}`} onDragEnter={(e) => dragEnter(e, index)}>
                                        <div
                                            className='drag-icon'
                                            draggable
                                            onClick={(e) => { e.stopPropagation() }}
                                            onDragStart={(e) => dragStart(e, index)}
                                            onDragEnd={dragEnd}
                                            title="Drag item"
                                        />
                                        <div className="title" onClick={() => { togglePanel(index) }}>
                                            {title}
                                        </div>

                                        <div className={`meatball ${showPanels.includes(index) ? 'active' : ''}`} onClick={() => { togglePanel(index) }}>
                                            <div className="mb-circle" /> <div className="mb-circle" /> <div className="mb-circle" />
                                        </div>
                                        <div className="row-del float-right" onClick={() => { delNote(index) }}><div className="iron-cross" /></div>

                                    </div>

                                    <div className={`row-panel ${showPanels.includes(index) ? 'open' : ''}`}>
                                        <div className="config-row">
                                            <div className="config-col">
                                                <textarea
                                                    className="config-textarea"
                                                    value={note}
                                                    onChange={(e) => { updateNote(index, e.target.value) }}
                                                />
                                            </div>
                                        </div>



                                    </div>


                                </div>
                            })
                        }

                        <div className="config-row">
                            <div className="config-col">
                                <div className="settings-add-btn" onClick={() => { createNote() }}>New Note</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </div>

    )
}


export default Notes
