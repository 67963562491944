import { useState } from 'react'

import { changeAlpha } from '../../lib/Utils'

function Messages(props) {
    const { device, save, setImageKey } = props

    const [selectedMsg, setSelectedMsg] = useState(false)
    const [newMsgThread, setNewMsgThread] = useState(false)
    const [dragIndex, setDragIndex] = useState(false)
    const [emojiPanel, setEmojiPanel] = useState(false)
    const [showPanels, setShowPanels] = useState([])

    function createMsgThread() {
        const newThread = {
            name: newMsgThread,
            image: '',
            new: false,
            bubbles: false,
            thread: [{
                inbound: true,
                text: "Hi"
            }],
        }

        save({ ...device.config, messages: [...device.config.messages, newThread] })

        setNewMsgThread(false)
    }

    function createMsgThreadFromContact(contact) {
        const newThread = {
            name: contact.name,
            image: contact.image,
            new: false,
            bubbles: false,
            thread: [{
                inbound: true,
                text: "Hi"
            }],
        }

        save({ ...device.config, messages: [...device.config.messages, newThread] })

        setNewMsgThread(false)
    }

    function updateMsgName(name, index) {
        const newThread = { ...device.config.messages[index], name: name }
        save({ ...device.config, messages: device.config.messages.map((existingMsg, i) => i === index ? newThread : existingMsg) })
    }

    function updateMsgText(text) {
        if (!selectedMsg) return

        const newMsg = { ...device.config.messages[selectedMsg.threadId].thread[selectedMsg.msgId], text: text }

        const newThread = {
            ...device.config.messages[selectedMsg.threadId],
            thread: device.config.messages[selectedMsg.threadId].thread.map((existingMsg, i) => i === selectedMsg.msgId ? newMsg : existingMsg)
        }

        save({ ...device.config, messages: device.config.messages.map((existingMsg, i) => i === selectedMsg.threadId ? newThread : existingMsg) })
    }

    function addMsg(index, text = '') {
        const newThread = [...device.config.messages[index].thread, { inbound: true, text: text }]

        const newConfig = {
            ...device.config, messages: device.config.messages.map((existingMsg, i) => i === index ? { ...device.config.messages[index], thread: newThread } : existingMsg)
        }

        save(newConfig)

        // select the last message
        setSelectedMsg({ threadId: index, msgId: newConfig.messages[index].thread.length - 1 })
    }

    function toggleInbound(threadId, msgId) {

        const newMsg = {
            ...device.config.messages[threadId].thread[msgId],
            inbound: !device.config.messages[threadId].thread[msgId].inbound
        }

        const newThread = {
            ...device.config.messages[threadId],
            thread: device.config.messages[threadId].thread.map((existingMsg, i) => i === msgId ? newMsg : existingMsg)
        }

        save({ ...device.config, messages: device.config.messages.map((existingMsg, i) => i === threadId ? newThread : existingMsg) })
    }

    function delMsg(threadId, msgId) {
        if (!window.confirm('Are you sure you want to delete?')) return

        const newThread = {
            ...device.config.messages[threadId],
            thread: device.config.messages[threadId].thread.map((existingMsg, i) => i === msgId ? null : existingMsg).filter(n => n)
        }

        save({ ...device.config, messages: device.config.messages.map((existingMsg, i) => i === threadId ? newThread : existingMsg) })
    }

    const dragStart = (e, index) => {
        e.dataTransfer.setDragImage(document.createElement('span'), 0, 0)
        setDragIndex(index)
    }

    const dragEnter = (e, target) => {
        if (dragIndex === target) return

        const copyMsgs = [...device.config.messages]
        copyMsgs.splice(dragIndex, 1)
        copyMsgs.splice(target, 0, device.config.messages[dragIndex])
        save({ ...device.config, messages: copyMsgs })

        // save the new index for future dragging
        setDragIndex(target)
    }

    const dragEnd = () => {
        setDragIndex(false)
    }

    function delMsgThread(index) {
        if (!window.confirm('Are you sure?')) return

        const msgs = [...device.config.messages]
        msgs.splice(index, 1)
        save({ ...device.config, messages: msgs })
    }

    function sendNotification(threadIndex, msgIndex) {
        const msgObj = device.config.messages[threadIndex]

        const notification = {
            icon: "https://www.prop-os.com/apps/messages.png",
            appName: "messages",
            index: threadIndex,
            title: msgObj.name,
            text: msgObj.thread[msgIndex].text,
            when: "now"
        }

        save({ ...device.config, pushNotifications: [...device.config.pushNotifications, notification] })
        console.log('new notifications', [...device.config.pushNotifications, notification])

        window.socket.sendEvent({
            event: 'notification',
            notification: notification
        })
    }

    function setField(index, key, value) {
        const newThread = { ...device.config.messages[index], [key]: value }
        save({ ...device.config, messages: device.config.messages.map((existingMsg, i) => i === index ? newThread : existingMsg) })
    }

    function togglePanel(index) {
        if (showPanels.includes(index)) {
            setShowPanels([...showPanels].filter(function (e) { return e !== index }))
        } else {
            setShowPanels([...showPanels, index])
        }
    }

    function messageBubble(threadIndex, msgIndex, thread) {
        return <div
            className="msgBubble"
            title="click to edit"
            onClick={() => { setSelectedMsg({ threadId: threadIndex, msgId: msgIndex }) }}
            style={{
                backgroundColor: changeAlpha(device.config.theme.highlight, 0.5),
                color: device.config.theme.color
            }}
        >
            {thread.text}
        </div>
    }

    function attachmentBubble(threadIndex, msgIndex, thread) {
        return <div
            className="attachment-bubble"
            title="click to edit"
            onClick={() => { setSelectedMsg({ threadId: threadIndex, msgId: msgIndex }) }}
        >
            <img className="attachment-preview" alt="attachment" src={thread.text.split('::')[1]} />
        </div>
    }

    function addEmoji(emoji) {
        updateMsgText(device.config.messages[selectedMsg.threadId].thread[selectedMsg.msgId].text + emoji)
    }

    const emojiList = [
        '😀', '😆', '😉', '😂', '🤣', '🙂', '😐', '😊', '😕', '😮', '😡', '🤬',
        '😇', '🥰', '😍', '🤩', '😘', '😛', '😎', '😳', '😈',
        '😜', '🤪', '🤭', '🤔', '🤐', '🙄', '😴', '🤮', '🥵', '😵', '🤯', '🥳', '😭',
        '🫠', '💩', '👻', '👾', '🤖', '💯', '💤',
        '❤', '💖', '💕', '💔', '💋',
        '🤚', '👌', '✌', '🤞', '🤘', '👍', '👎', '✊', '👊', '👏', '🤝', '🙏', '💪',
        '👶', '🧒', '🙍‍♂️', '🙍‍♀️', '🤦‍♂️', '🤦‍♀️', '🦄', '🐮', '🐷',
        '🍌', '🍎', '🍏', '🍑', '🍒', '🍓', '🥝', '🥥', '🍆', '🌽', '🥕', '🍔', '🍕'
    ]

    const isEmoji = (str) => {
        const emoji = /\p{Extended_Pictographic}/u
        const alpha = /[a-zA-Z0-9_]/
        return emoji.test(str) && !alpha.test(str)
    }

    if (!device) return <div></div>

    return (
        <div className="panel dragging-panel">
            <div className="config-row">
                <div className="config-col">
                    <p>
                        These are the message threads the user will see when they open the messages app
                    </p>

                    {
                        device.config.messages && device.config.messages.map((msgThread, index) => {
                            return <div key={`msg_${index}`}>
                                <div className={`list-item ${index === dragIndex ? 'active-app-item' : ''}`} onDragEnter={(e) => dragEnter(e, index)}>
                                    <div
                                        className='drag-icon'
                                        draggable
                                        onClick={(e) => { e.stopPropagation() }}
                                        onDragStart={(e) => dragStart(e, index)}
                                        onDragEnd={dragEnd}
                                    />
                                    <img className="icon avatar" alt={msgThread.name} onClick={() => { setImageKey(`messages:${index}:image`) }} src={msgThread.image ? msgThread.image : '/unknown_contact.png'} />
                                    <div className="title">
                                        <input
                                            className="text-input"
                                            value={msgThread.name}
                                            onClick={(e) => { e.stopPropagation() }}
                                            onChange={(e) => { updateMsgName(e.target.value, index) }}
                                        />
                                    </div>

                                    <div className={`meatball ${showPanels.includes(index) ? 'active' : ''}`} onClick={() => { togglePanel(index) }}>
                                        <div className="mb-circle" /> <div className="mb-circle" /> <div className="mb-circle" />
                                    </div>
                                    <div className="row-del" onClick={() => { delMsgThread(index) }}><div className="iron-cross" /></div>
                                </div>

                                {/* --- PANEL --- */}

                                <div className={`row-panel ${showPanels.includes(index) ? 'open' : ''}`}>
                                    <div className='msgThread' style={{
                                        backgroundColor: device.config.theme.background,
                                        color: device.config.theme.color
                                    }}>
                                        {
                                            msgThread.thread.map((thread, i) => {
                                                const isAttachment = thread.text.includes('attachment:')
                                                return <div
                                                    className={`msg-row ${thread.inbound ? 'inbound' : 'outbound'} ${isEmoji(thread.text) ? 'single-emoji' : ''}`}
                                                    key={'threaded_msg_' + index + '_' + i}
                                                >
                                                    {isAttachment && attachmentBubble(index, i, thread)}
                                                    {!isAttachment && messageBubble(index, i, thread)}

                                                    <div className="thread-btn-row">
                                                        <div className="toggle-inbound" onClick={() => { toggleInbound(index, i) }}><div className="iron-chev" /></div>
                                                        {thread.inbound && <div className="send-pn" title="Send Push Notification" onClick={() => { sendNotification(index, i) }}></div>}
                                                        <div className="row-del" onClick={() => { delMsg(index, i) }}><div className="iron-cross" /></div>
                                                    </div>

                                                </div>
                                            })
                                        }

                                        <div className="msgs-btns-row">
                                            <div className="new-msg-btn" onClick={() => { setImageKey(`messageAttachment:${index}`) }}>Add Image</div>
                                            <div className="new-msg-btn" onClick={() => { addMsg(index) }}>Add Message</div>
                                        </div>

                                    </div>

                                    <div className="config-row">
                                        <div className="config-col">
                                            New message badge
                                        </div>
                                        <div className="config-col">
                                            <label className="switch float-right" >
                                                <input type="checkbox" checked={msgThread.new} onChange={() => { setField(index, 'new', !msgThread.new) }} />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="config-row">
                                        <div className="config-col">
                                            Bubbles
                                        </div>
                                        <div className="config-col">
                                            <label className="switch float-right" >
                                                <input type="checkbox" checked={msgThread.bubbles} onChange={() => { setField(index, 'bubbles', !msgThread.bubbles) }} />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        })
                    }

                    <div className="settings-add-btn" onClick={() => { setNewMsgThread('') }}>New Message Thread</div>

                    {/* MODALS */}

                    {
                        newMsgThread !== false && <div>
                            <div className="matte" onClick={() => { setNewMsgThread(false) }}></div>
                            <div className="modal-container">
                                <h4>New Messages Thread</h4>
                                <p className="text-center left100">-- Just add a name --</p>
                                <input
                                    onChange={(e) => { setNewMsgThread(e.target.value) }}
                                    value={newMsgThread}
                                    placeholder="Marty MckFly"
                                    className="title-input"
                                />
                                <div className="btn btn-primary left100" onClick={createMsgThread}>Create message thread with name</div>

                                <p className="text-center left100 mtop-30">-- Or start from an existing contact --</p>
                                <div className="contact-shortlist">
                                    {
                                        device.config.contacts.map(contact => <div
                                            className="contact"
                                            key={`msg_contact_${contact.name}`}
                                            onClick={() => { createMsgThreadFromContact(contact) }}
                                        >
                                            {contact.name}
                                        </div>)
                                    }
                                </div>

                            </div>
                        </div>

                    }

                    {
                        selectedMsg && device.config.messages[selectedMsg.threadId].thread[selectedMsg.msgId] && < div >
                            <div className="matte" onClick={() => { setSelectedMsg(false) }}></div>
                            <div className="modal-container">
                                <h4>Message
                                    {device.config.messages[selectedMsg.threadId].thread[selectedMsg.msgId].inbound ? ' FROM ' : ' TO '}
                                    {device.config.messages[selectedMsg.threadId].name}
                                </h4>
                                <textarea
                                    autoFocus
                                    value={device.config.messages[selectedMsg.threadId].thread[selectedMsg.msgId].text}
                                    onChange={(e) => { updateMsgText(e.target.value) }}
                                />
                                <div className="btn btn-primary float-right" onClick={() => { setSelectedMsg(false) }}>Done</div>
                                <div className="btn float-right mright-20" onClick={() => { toggleInbound(selectedMsg.threadId, selectedMsg.msgId) }}>To/From</div>
                                <div className="btn float-right mright-20" onClick={() => { sendNotification(selectedMsg.threadId, selectedMsg.msgId) }}>Send Notification</div>
                                <div className="btn float-right mright-20" onClick={() => { setEmojiPanel(!emojiPanel) }}>😀</div>

                                <div className={`emoji-list ${emojiPanel ? 'open' : 'closed'}`}>
                                    {
                                        emojiList.map((emoji, index) => <span key={`emoji_${index}`} onClick={() => { addEmoji(emoji) }}>{emoji}</span>)
                                    }

                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}


export default Messages
