import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from "react-router-dom"

import Page from '../components/layouts/Page'
import { useTitle } from '../lib/Utils'


function Dashboard() {
    const user = useSelector((state) => state.home.user)

    useTitle('Dashboard')

    if (!user.token)
        return <Page><Link to="/login">Sign In Required</Link></Page>

    return (
        <Page>
            <h1 className='text-center'>Dashboard</h1>
            <p className="intro">
                This is your dashboard, where you can see a list of the productions you're working on. There'll be more stuff here as the product matures.
            </p>
            <div className='panel'>
                <h4>Productions</h4>
                {
                    user.profile.productions.length < 1 && <div className='notice'>You haven't created any productions yet.</div>
                }
                {
                    user.profile.productions.map((production, index) => {
                        return <Link to={`/production/${production.external_id}`} className="production-row" key={`production_${index}`}>
                            <h5>{production.name}</h5>
                            <span className='production-stats'>(
                                {production.devices.length} device{production.devices.length !== 1 ? 's ' : ' '}
                                {production.uploads.length} upload{production.uploads.length !== 1 ? 's' : ''}
                                )</span>
                        </Link>
                    })
                }
                <Link to="/new-production" className='production-row add-tile'>
                    <span />
                    New
                </Link>
            </div>

        </Page>
    )
}

export default Dashboard
