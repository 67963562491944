
function Handsets(props) {
    const { device } = props

    if (!device) return <div></div>

    return (
        <div className="panel">
            <div className="config-row">
                <div className="config-col">
                    <p className="text-center">
                        Open the prop-OS app on your phone, tap on Settings and click "Link to propOS Account".
                        Then enter the following PIN
                    </p>
                    <div className="row">
                        {
                            device.handsets && <div className='handset-list'>
                                {
                                    <div className="handset-pin" >{device.handsets[0].pin}</div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}


export default Handsets
