import React, { useState } from 'react'

function Slider(props) {
    const { value, onUpdate, min, max } = props
    const [isDragging, setIsDragging] = useState(false)

    function startDrag(e) {
        setIsDragging(true)
        document.addEventListener("mouseup", stopDrag)
        document.addEventListener("touchstop", stopDrag)
    }

    function doDrag(e) {
        if (!isDragging) return

        let x = 0
        if (e.touches) {
            x = e.touches[0].clientX
        } else {
            x = e.clientX
        }

        let elem = e.target
        if (elem.classList.contains('slider-pin')) {
            elem = elem.parentElement
        }

        const getRect = elem.getBoundingClientRect()
        let num = x - getRect.x
        num = num < 0 ? 0 : num
        let value = Math.round((max - min) * (num / 200) + min)

        if (value > max) value = max
        onUpdate(value)

    }

    function stopDrag() {
        setIsDragging(false)
        document.removeEventListener("mouseup", stopDrag)
        document.removeEventListener("touchstop", stopDrag)
    }

    function getLeft() {
        const left = Math.round(200 * (value / (max - min))) - min - 15
        return left
    }

    return (
        <div
            className="slider-track"
            onMouseDown={startDrag}
            onMouseMove={doDrag}
            onTouchStart={startDrag}
            onTouchMove={doDrag}
        >
            <div className="slider-pin" style={{ left: getLeft() }}></div>
        </div>
    )
}


export default Slider
