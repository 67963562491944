import { useState } from 'react'

function Mail(props) {

    const [showPanels, setShowPanels] = useState([])
    const { device, save } = props

    function createEmail(mailbox) {
        const email = {
            from: 'info@place.com',
            to: device.name,
            content: 'Hey there\n\n\n',
            time: "1.30pm 12 July",
            subject: "New Email",
            new: true
        }


        save(
            {
                ...device.config, mail: {
                    ...device.config.mail, [mailbox]: [...device.config.mail[mailbox], email]
                }
            })
    }

    function delMail(mailbox, index) {
        if (!window.confirm('Are you sure?')) return

        const box = [...device.config.mail[mailbox]]
        box.splice(index, 1)
        save(
            {
                ...device.config, mail: {
                    ...device.config.mail, [mailbox]: box
                }
            })
    }


    function updateField(mailbox, index, key, value) {
        const newItem = { ...device.config.mail[mailbox][index], [key]: value }
        const newMailbox = device.config.mail[mailbox].map((existingMsg, i) => i === index ? newItem : existingMsg)
        save({
            ...device.config, mail: {
                ...device.config.mail, [mailbox]: newMailbox
            }
        })
    }

    function togglePanel(index) {
        if (showPanels.includes(index)) {
            setShowPanels([...showPanels].filter(function (e) { return e !== index }))
        } else {
            setShowPanels([...showPanels, index])
        }
    }




    if (!device) return <div></div>

    return (
        <div>
            <div className="panel">
                <div className="config-row">
                    <div className="config-col">
                        <h4>Mail App</h4>

                        {
                            device.config.mail &&
                            ["Inbox", "Drafts", "Sent", "Junk", "Trash"].map((name, index) => {
                                const mailbox = device.config.mail[name]
                                return <div key={`mailbox_${name}`}>
                                    <div className='list-item'>
                                        <div className="title">
                                            {name} ({mailbox.length})
                                        </div>

                                        <div className={`meatball ${showPanels.includes(index) ? 'active' : ''}`} onClick={() => { togglePanel(index) }}>
                                            <div className="mb-circle" /> <div className="mb-circle" /> <div className="mb-circle" />
                                        </div>

                                    </div>

                                    <div className={`row-panel ${showPanels.includes(index) ? 'open' : ''}`}>

                                        {
                                            mailbox.map((mail, mailIndex) => <div className="panel" key={`${name}_mail_${mailIndex}`}>
                                                <div className="mail-del-holder">
                                                    <div className="row-del float-right" onClick={() => { delMail(name, mailIndex) }}><div className="iron-cross" /></div>
                                                </div>
                                                <div className="config-row">
                                                    <div className="config-col">
                                                        From:
                                                    </div>
                                                    <div className="config-col">
                                                        <input
                                                            className="text-input full-width"
                                                            value={mail.from}
                                                            onClick={(e) => { e.stopPropagation() }}
                                                            onChange={(e) => { updateField(name, mailIndex, 'from', e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="config-row">
                                                    <div className="config-col">
                                                        To:
                                                    </div>
                                                    <div className="config-col">
                                                        <input
                                                            className="text-input full-width"
                                                            value={mail.to}
                                                            onClick={(e) => { e.stopPropagation() }}
                                                            onChange={(e) => { updateField(name, mailIndex, 'to', e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="config-row">
                                                    <div className="config-col">
                                                        Subject:
                                                    </div>
                                                    <div className="config-col">
                                                        <input
                                                            className="text-input full-width"
                                                            value={mail.subject}
                                                            onClick={(e) => { e.stopPropagation() }}
                                                            onChange={(e) => { updateField(name, mailIndex, 'subject', e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="config-row">
                                                    <div className="config-col">
                                                        Unread
                                                    </div>
                                                    <div className="config-col">
                                                        <label className="switch float-right" >
                                                            <input type="checkbox" checked={mail.new} onChange={() => { updateField(name, mailIndex, 'new', !mail.new) }} />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="config-row">
                                                    <div className="config-col">
                                                        Time:
                                                    </div>
                                                    <div className="config-col">
                                                        <input
                                                            className="text-input full-width"
                                                            value={mail.time}
                                                            onClick={(e) => { e.stopPropagation() }}
                                                            onChange={(e) => { updateField(name, mailIndex, 'time', e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="config-row">
                                                    <div className="config-col">
                                                        <textarea
                                                            className="config-textarea"
                                                            value={mail.content}
                                                            onChange={(e) => { updateField(name, mailIndex, 'content', e.target.value) }}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            )
                                        }

                                        <div className="config-row">
                                            <div className="config-col">
                                                <div className="settings-add-btn" onClick={() => { createEmail(name) }}>New Email</div>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            })
                        }



                    </div>
                </div>
            </div>


        </div>

    )
}


export default Mail
