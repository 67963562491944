import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom"

import { Row, Col, Form, Button } from 'react-bootstrap'

import Page from '../components/layouts/Page'
import TextInput from '../components/inputs/Text'
import Validator from '../lib/Validator'
import { requestLogin } from '../lib/Api'
import { login, setFlash } from '../features/user/homeSlice'


function Login() {
    const dispatch = useDispatch()
    const history = useHistory()
    const validation_object = 'user'

    const [values, setValues] = useState({ email: '', password: '' })
    const [errors, setErrors] = useState({})

    const user = useSelector((state) => state.home.user)

    useEffect(() => {
        if(user.token)
            history.push('/dashboard')
})

    function handleChange(name, value){
        const newValues = {...values, [name]: value}
        setValues(newValues)
        setErrors(Validator(validation_object, newValues))
    }

    function submitForm(e) {
        e.preventDefault()

        // ensure we have current values (autofill)
        let newValues = values
        let inputs = document.getElementsByTagName('input')
        for (let i = 0; i < inputs.length; ++i) {
            if(Object.keys(values).includes(inputs[i].name)){
                newValues = {...newValues, [inputs[i].name]: inputs[i].value}
            }
        }

        const errors = Validator(validation_object, newValues)
        setValues(newValues)
        setErrors(errors)

        if(Object.keys(errors).length) return false

        const request = requestLogin(newValues)
        request.then((response) => {
            dispatch(login(response.data))
            dispatch(setFlash("You're logged in"))
        }).catch((error) => {
            if(error.response && error.response.data){
                dispatch(setFlash(error.response.data))
            }else{
                dispatch(setFlash('Login Failed'))
            }
        })

        return false
    }
    
    return (
        <Page>
            <Row>
                <Col md={{ span: 6, offset: 3 }} className="text-center">
                    <h2>Sign In</h2>
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 6, offset: 3 }}>
                    <Form onSubmit={submitForm}>
                        <TextInput
                            name="email"
                            title="Email"
                            onUpdate={handleChange}
                            placeholder="marty@mcfly.com"
                            errors={errors}
                            required={true}
                            inputType='email'
                        />

                        <TextInput
                            name="password"
                            title="Password"
                            onUpdate={handleChange}
                            placeholder="you chicken?"
                            errors={errors}
                            required={true}
                            inputType='password'
                        />

                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
            
        </Page>
    )
}

export default Login
