import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom"

import { requestBuckles, requestBucklesUpdateUser, requestBucklesProductions } from '../../lib/Api'
import { setFlash, setProductions } from '../../features/user/homeSlice'

import Page from '../../components/layouts/Page'
import Checkbox from '../../components/inputs/Checkbox'

import { timeSince, formatDatetime } from '../../lib/Utils'



function AdminIndex() {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.home.user)
    const history = useHistory()

    const [buckles, setBuckles] = useState(false)

    useEffect(() => {
        if (user.token == null || !user.isStaff) {
            return
        }

        if (!buckles) {
            const request = requestBuckles()
            request.then((response) => {
                setBuckles(response.data)
            }).catch((error) => {
                dispatch(setFlash('Failed'))
            })
        }

    })

    const checkboxUpdate = (params, value) => {
        params.value = value

        const request = requestBucklesUpdateUser(params)
        request.then((response) => {
            setBuckles(response.data)
        }).catch((error) => {
            dispatch(setFlash('Failed'))
        })
    }

    const spoofUser = (user_id, production_id, device_id) => {
        console.log('get productions')
        const request = requestBucklesProductions(user_id)
        setTimeout(() => { history.push('/production/' + production_id + '/device/' + device_id) }, 500)
        request.then((response) => {
            dispatch(setProductions(response.data))
        }).catch((error) => {
            console.log(error)
        })
    }


    if (!user.token || !user.isStaff)
        return <Page>404 Etc</Page>

    if (!buckles)
        return <Page>Loading...</Page>

    return (
        <Page>
            <div className='hero-page-content'>
                <div className="panel">

                    <h5>Users</h5>
                    <hr />

                    <div className="my-table buckles" >
                        <div className="my-row title-row">
                            <div className="col">Username</div>
                            <div className="col text-center">Active</div>
                            <div className="col text-center">Paid</div>
                            <div className="col text-center">Staff</div>
                            <div className="col text-center">Last Seen</div>
                        </div>
                        {
                            buckles.users.map((user) => {
                                return <div className="my-row" key={`user_${user.id}`}>
                                    <div className="col">{user.username}</div>
                                    <div className="col text-center">
                                        <Checkbox
                                            onUpdate={checkboxUpdate}
                                            obj={{ type: 'user', property: 'is_active', id: user.profile.id }}
                                            value={user.is_active}
                                        />

                                    </div>
                                    <div className="col text-center">
                                        <Checkbox
                                            onUpdate={checkboxUpdate}
                                            obj={{ type: 'profile', property: 'is_paid', id: user.profile.id }}
                                            value={user.profile.is_paid}
                                        />
                                    </div>
                                    <div className="col text-center">
                                        <Checkbox
                                            onUpdate={checkboxUpdate}
                                            obj={{ type: 'user', property: 'is_staff', id: user.profile.id }}
                                            value={user.is_staff}
                                        />
                                    </div>
                                    <div className="col text-center">{user.profile.last_seen && timeSince(user.profile.last_seen)}</div>
                                </div>

                            })
                        }
                    </div>
                </div>
                <div className="panel p-and-d">

                    <h5>Productions & Devices</h5>

                    {
                        buckles.users.map((user) => {
                            return <div key={`production_user_${user.id}`} className="pd_user">
                                <h5>{user.username}</h5>
                                {user.profile.productions.map((production) => {
                                    return <div key={`production_${production.external_id}`} className="pd_prod">
                                        {production.name}
                                        {
                                            production.devices.map(device => <div
                                                key={`device_${device.external_id}`}
                                                className="pd_device"
                                                onClick={() => { spoofUser(user.id, production.external_id, device.external_id) }}
                                            >
                                                {device.name}
                                                <span title="Modified" className="float-right text-right">{formatDatetime(device.modified_at)}</span>
                                            </div>)
                                        }
                                    </div>
                                })}
                            </div>
                        })
                    }
                </div>
            </div>

        </Page >
    )
}

export default AdminIndex
