import { useState } from 'react'

function Browser(props) {

    const [showPanels, setShowPanels] = useState([])
    const { device, save } = props

    function createResult() {
        const result = {
            title: 'Bill Hader',
            website: 'Wikipedae',
            content: 'Bill Hader is an American actor, writer, director and comedian',
            link: "wikipedae.com/bill-hader"
        }

        save(
            {
                ...device.config, browser: {
                    ...device.config.browser, "asimov.com": {
                        ...device.config.browser["asimov.com"], results: [
                            ...device.config.browser["asimov.com"].results, result
                        ]
                    }
                }
            })
    }

    function delResult(index) {
        if (!window.confirm('Are you sure?')) return

        return

        const calls = [...device.config.recentCalls]
        calls.splice(index, 1)
        save({ ...device.config, recentCalls: calls })
    }


    function updateField(index, key, value) {
        const newItem = { ...device.config.browser['asimov.com'].results[index], [key]: value }
        save({
            ...device.config, browser: {
                ...device.config.browser, "asimov.com": {
                    ...device.config.browser["asimov.com"], results: device.config.browser['asimov.com'].results.map((existing, i) => i === index ? newItem : existing)
                }
            }
        })
    }

    function togglePanel(index) {
        if (showPanels.includes(index)) {
            setShowPanels([...showPanels].filter(function (e) { return e !== index }))
        } else {
            setShowPanels([...showPanels, index])
        }
    }




    if (!device) return <div></div>

    return (
        <div>
            <div className="panel">
                <div className="config-row">
                    <div className="config-col">
                        <h4>Custom Search Results</h4>
                        <p>
                            When a user searches for something, these results will be displayed before random generated content
                        </p>


                        {
                            device.config.browser &&
                            device.config.browser['asimov.com'].results.map((item, index) => {
                                return <div key={`recent_${index}`}>
                                    <div className='list-item'>
                                        <div className="title">
                                            <input
                                                className="text-input"
                                                value={item.title}
                                                onClick={(e) => { e.stopPropagation() }}
                                                onChange={(e) => { updateField(index, 'title', e.target.value) }}
                                            />
                                        </div>

                                        <div className={`meatball ${showPanels.includes(index) ? 'active' : ''}`} onClick={() => { togglePanel(index) }}>
                                            <div className="mb-circle" /> <div className="mb-circle" /> <div className="mb-circle" />
                                        </div>
                                        <div className="row-del" onClick={() => { delResult(index) }}><div className="iron-cross" /></div>
                                    </div>

                                    <div className={`row-panel ${showPanels.includes(index) ? 'open' : ''}`}>
                                        <div className="config-row">
                                            <div className="config-col">
                                                <b>Website Name</b><br />
                                            </div>
                                            <div className="config-col">
                                                <input
                                                    className="text-input full-width"
                                                    value={item.website}
                                                    onClick={(e) => { e.stopPropagation() }}
                                                    onChange={(e) => { updateField(index, 'website', e.target.value) }}
                                                />
                                            </div>
                                        </div>

                                        <div className="config-row">
                                            <div className="config-col">
                                                <b>Website Link</b><br />
                                            </div>
                                            <div className="config-col">
                                                <input
                                                    className="text-input full-width"
                                                    value={item.link}
                                                    onClick={(e) => { e.stopPropagation() }}
                                                    onChange={(e) => { updateField(index, 'link', e.target.value) }}
                                                />
                                            </div>
                                        </div>

                                        <div className="config-row">
                                            <div className="config-col">
                                                <b>Result Summary</b><br />
                                            </div>
                                            <div className="config-col">
                                                <input
                                                    className="text-input full-width"
                                                    value={item.content}
                                                    onClick={(e) => { e.stopPropagation() }}
                                                    onChange={(e) => { updateField(index, 'content', e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            })
                        }


                        <div className="settings-add-btn" onClick={() => { createResult() }}>New Custom Result</div>

                    </div>
                </div>
            </div>


        </div>

    )
}


export default Browser
