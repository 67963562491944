
import { useDispatch } from 'react-redux'
import { Form } from 'react-bootstrap'
import { Link } from "react-router-dom"

import TextInput from '../components/inputs/Text'
import Preview from '../components/devicePanels/preview'
import { formatDatetime } from '../lib/Utils'

import { requestDeleteDevice, requestUpdateDevice, requestCloneDevice } from '../lib/Api'
import { setProductions, setFlash } from '../features/user/homeSlice'

function Devices(props) {
    const dispatch = useDispatch()
    const { production } = props

    const delDevice = (external_id) => {
        if (!window.confirm('Are you sure you want to delete? This cannot be undone.')) return

        const request = requestDeleteDevice(external_id)
        request.then((response) => {
            dispatch(setProductions(response.data))
            dispatch(setFlash(`Upload Deleted`))
        }).catch((error) => {
            dispatch(setFlash('Failed'))
        })
    }

    function handleNameUpdate(external_id, name) {
        if (name.length < 2) return dispatch(setFlash('Error: Name too short'))

        const request = requestUpdateDevice(external_id, { name: name })
        request.then((response) => {
            dispatch(setProductions(response.data))
            dispatch(setFlash(`Changes saved`))
        }).catch((error) => {
            dispatch(setFlash('Failed'))
        })
    }

    function cloneDevice(external_id) {
        if (!window.confirm('Are you sure you want to create a  clone?')) return
        const request = requestCloneDevice(external_id)
        request.then((response) => {
            dispatch(setProductions(response.data))
            dispatch(setFlash(`Changes saved`))
        }).catch((error) => {
            dispatch(setFlash('Failed'))
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
        document.activeElement.blur()
    }

    // Rendering -----

    const sortedDevices = (by, dir = 'asc') => {
        if (dir === 'asc') {
            return [...production.devices].sort((a, b) => (a[by] > b[by]) ? 1 : ((b[by] > a[by]) ? -1 : 0))
        }
        return [...production.devices].sort((a, b) => (a[by] < b[by]) ? 1 : ((b[by] < a[by]) ? -1 : 0))
    }

    if (!production) return <div>Whah?</div>

    return (
        <div className='panel float-left'>
            <h4>Devices</h4>

            {
                sortedDevices('name').map((device) => {
                    return <div key={`device_${device.external_id}`} className="device-tile">
                        <Link to={`/production/${production.external_id}/device/${device.external_id}`} className='tile-preview'>
                            <Preview device={device} screen="homeScreen" />
                        </Link>
                        <Form onSubmit={handleSubmit} className="device-tile-title">
                            <TextInput
                                name={device.external_id}
                                title="Name"
                                showTitle={false}
                                onUpdate={handleNameUpdate}
                                placeholder="The Wire"
                                errors={[]}
                                required={true}
                                defaultValue={device.name}
                                inputType='text'
                            />
                        </Form>

                        <span className="date">{formatDatetime(device.created_at)}</span>
                        <div className="row-del" onClick={() => { delDevice(device.external_id) }}><div className="iron-cross" /></div>
                        <div className="clone-btn" title="clone" onClick={() => { cloneDevice(device.external_id) }}></div>
                    </div>
                })
            }

            <Link to={`/production/${production.external_id}/new-device`} className='device-tile add-tile'>
                <span />
                New
            </Link>
        </div>

    )
}


export default Devices
