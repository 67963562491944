import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

function Text(props) {
    const { name, title, placeholder, errors, required, onUpdate, inputType, as, defaultValue, showTitle } = props

    const [value, setValue] = useState(defaultValue ? defaultValue : '')
    const [touched, setTouched] = useState(false)

    function blur(e) {
        if (!value == null) return
        setTouched(true)
        onUpdate(name, value)
    }

    function changed(e) {
        setValue(e.target.value)
    }


    return (
        <Form.Group className="mb-3" controlId={`formBasic` + name}>
            {showTitle !== false && <Form.Label>{title}{required && <span className="required" title="Required">*</span>}</Form.Label>}
            <Form.Control
                type={inputType}
                as={as}
                name={name}
                placeholder={placeholder}
                onChange={changed}
                onBlur={blur}
                value={value}
            />
            {touched && errors[name] &&
                <Form.Text className="error">
                    {errors[name]}
                </Form.Text>
            }
        </Form.Group>
    )
}


export default Text
