import { useState } from 'react'

import { Dropdown } from 'react-bootstrap'
import DateTimePicker from 'react-datetime-picker'

function Phone(props) {
    const { device, save, setImageKey, startCall } = props

    const [showPanels, setShowPanels] = useState([])
    const [adhocCaller, setAdhocCaller] = useState('')

    function createNewCall() {
        const call = {
            name: 'Caller ID',
            image: '',
            type: 'inbound',
            time: device.config.date
        }

        save({ ...device.config, recentCalls: [...device.config.recentCalls, call] })
    }

    function delCall(index) {
        if (!window.confirm('Are you sure?')) return

        const calls = [...device.config.recentCalls]
        calls.splice(index, 1)
        save({ ...device.config, recentCalls: calls })
    }

    function updateRecentName(name, index) {
        const newCall = { ...device.config.recentCalls[index], name: name }
        save({ ...device.config, recentCalls: device.config.recentCalls.map((existing, i) => i === index ? newCall : existing) })
    }

    function togglePanel(index) {
        if (showPanels.includes(index)) {
            setShowPanels([...showPanels].filter(function (e) { return e !== index }))
        } else {
            setShowPanels([...showPanels, index])
        }
    }

    function sortCalls() {
        const sorted = [...device.config.recentCalls]
        sorted.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
        save({ ...device.config, recentCalls: sorted })
    }

    function updateField(index, key, value) {
        const newItem = { ...device.config.recentCalls[index], [key]: value }
        save({ ...device.config, recentCalls: device.config.recentCalls.map((existing, i) => i === index ? newItem : existing) })
    }

    function formatTime(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes
        return strTime;
    }

    function formatDate(format, date) {
        if (format === 'dd/mm/yyyy') {
            const yyyy = date.getFullYear();
            let mm = date.getMonth() + 1
            let dd = date.getDate();

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;

            return dd + '/' + mm + '/' + yyyy;
        }

        if (format === 'long') {
            const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
            return [
                days[date.getDay()],
                date.toLocaleDateString('en-US', { day: 'numeric' }),
                date.toLocaleDateString('en-US', { month: 'long' })
            ].join(' ')
        }
    }

    // EVENTS

    // function incomingCall() {
    //     const callerID = window.prompt('Caller ID')
    //     window.socket.sendEvent({ event: 'incomingCall', callerId: callerID })
    // }

    if (!device) return <div></div>

    return (
        <div>
            <div className="panel">
                <div className="config-row">
                    <div className="config-col">
                        <h4>Incoming Call</h4>
                        <p>
                            Trigger an incoming call from any Caller ID
                        </p>

                        <div className="config-row">
                            <div className="config-col">
                                <b>Caller ID</b>
                            </div>
                            <div className="config-col">
                                <img className="float-right mleft-30 phone-action-icon" alt="call" src="/call_answer.png" onClick={() => { startCall(adhocCaller) }} />
                                <input className="input-field" value={adhocCaller} onChange={(e) => { setAdhocCaller(e.target.value) }} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel">
                <div className="config-row">
                    <div className="config-col">
                        <h4>Recent Calls</h4>
                        <p>
                            These is the recent calls list that the user will see in the phone app in the order that they will see them.<br />
                            <br />
                            Calls on the same date as the device will show the time of the call, otherwise the date of the call will be shown.
                        </p>
                        <div className="notice">
                            The Device date is set to {formatDate('long', new Date(device.config.date))} {formatTime(new Date(device.config.date))}.
                        </div>
                        {
                            device.config.recentCalls &&
                            device.config.recentCalls.map((item, index) => {
                                return <div key={`recent_${index}`}>
                                    <div className='list-item'>
                                        <img className="icon avatar" alt={item.name} onClick={() => { setImageKey(`recentCalls:${index}:image`) }} src={item.image ? item.image : '/unknown_contact.png'} />
                                        <div className="title">
                                            <input
                                                className="text-input"
                                                value={item.name}
                                                onClick={(e) => { e.stopPropagation() }}
                                                onChange={(e) => { updateRecentName(e.target.value, index) }}
                                                onBlur={(e) => { sortCalls() }}
                                            />
                                        </div>

                                        <div className={`meatball ${showPanels.includes(index) ? 'active' : ''}`} onClick={() => { togglePanel(index) }}>
                                            <div className="mb-circle" /> <div className="mb-circle" /> <div className="mb-circle" />
                                        </div>
                                        <div className="row-del" onClick={() => { delCall(index) }}><div className="iron-cross" /></div>
                                    </div>

                                    <div className={`row-panel ${showPanels.includes(index) ? 'open' : ''}`}>
                                        <div className="config-row">
                                            <div className="config-col">
                                                <b>Type of Call</b>
                                            </div>
                                            <div className="config-col">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                        {item.type}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => { updateField(index, 'type', 'inbound') }}>Inbound</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => { updateField(index, 'type', 'outbound') }}>Outbound</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => { updateField(index, 'type', 'missed') }}>Missed</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div className="config-row">
                                            <div className="config-col">
                                                <b>Date Time</b><br />
                                            </div>
                                            <div className="config-col">
                                                <DateTimePicker
                                                    onChange={(e) => { updateField(index, 'time', e.toString()) }}
                                                    onBlur={sortCalls}
                                                    value={new Date(item.time)}
                                                    clockIcon={false}
                                                    clearIcon={false}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            })
                        }

                        <div className="settings-add-btn" onClick={() => { createNewCall() }}>New Recent Call</div>
                    </div>
                </div>

            </div>

            <div className="panel">
                <div className="config-row">
                    <div className="config-col">
                        <h4>Contacts</h4>
                        <p>
                            You can trigger incoming calls from this list
                        </p>
                        {
                            device.config.contacts &&
                            device.config.contacts.map((item, index) => {
                                return <div key={`phone_conts_${index}`}>
                                    <div className='list-item'>
                                        <img className="icon avatar" alt={item.name} src={item.image ? item.image : '/unknown_contact.png'} />
                                        <div className="title"> {item.name} </div>
                                        <img className="phone-action-icon" alt="call" src="/call_answer.png" onClick={() => { startCall(item.name, item.image) }} />
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>

            </div>
        </div>

    )
}


export default Phone
