import React from 'react'
import { Row, Card, Col, Button } from 'react-bootstrap'

import Page from '../components/layouts/Page'


function Index() {

    return (
        <Page hero>
            <div className='hero-page-content'>
                <Row className="mx-0 text-center justify-content-md-center">
                    <Col md={8}>
                        <Card>

                            <Card.Body>
                                <Card.Title>Simple control of interactive props</Card.Title>
                                <Card.Text>
                                    PropOS turns a phone into a reliable prop. Just open the app to completely hide the original operating system.
                                </Card.Text>
                                <Button href="/how-it-works">See how it works</Button>
                            </Card.Body>
                            <Card.Body>
                                <Card.Title>Configure the Phone</Card.Title>
                                <Card.Text>
                                    Define the battery level, cell and wifi signal, time, the installed apps, the wallpaper - all in an easy to use online control panel.
                                </Card.Text>
                            </Card.Body>
                            <img className="how-to-img" src="/images/how-to-02.jpg" alt="Configure the phone in a simple to use control panel" />
                        </Card>
                    </Col>
                </Row>

                <Row className="mx-0 text-center justify-content-md-center mt-3">
                    <Col md={8}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Fire Events</Card.Title>
                                <Card.Text>
                                    Use the control panel to send messages and trigger incoming calls on the phone. No need for SIM cards or cell reception - stable and instant events over wifi.
                                </Card.Text>
                            </Card.Body>
                            <img className="how-to-img" src="/images/how-to-03.jpg" alt="Manage events easily and instantly, no sim card required" />
                        </Card>
                    </Col>
                </Row>
            </div>

        </Page>
    )
}

export default Index
