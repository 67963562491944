import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom"
import { Row, Col, Form, Button } from 'react-bootstrap'
import { Link } from "react-router-dom"

import Page from '../components/layouts/Page'
import TextInput from '../components/inputs/Text'
import Validator from '../lib/Validator'
import { requestSignUp } from '../lib/Api'
import { login, setFlash } from '../features/user/homeSlice'


function Signup() {
    const dispatch = useDispatch()
    const history = useHistory()

    const [values, setValues] = useState({ email: '', password: '' })
    const [errors, setErrors] = useState({})

    const user = useSelector((state) => state.home.user)
    const validation_object = 'user'

    useEffect(() => {
        if (user.token) {
            history.push(`/production/${user.profile.productions[0].external_id}`)
        }

    })


    function handleChange(name, value) {
        const newValues = { ...values, [name]: value }
        setValues(newValues)
        setErrors(Validator(validation_object, newValues))
    }

    function submitForm(e) {
        e.preventDefault()

        // swap out values for autofilled values
        let newValues = values
        let inputs = document.getElementsByTagName('input')
        for (let i = 0; i < inputs.length; ++i) {
            if (Object.keys(values).includes(inputs[i].name)) {
                newValues = { ...newValues, [inputs[i].name]: inputs[i].value }
            }
        }

        const errors = Validator(validation_object, newValues)
        setValues(newValues)
        setErrors(errors)

        if (Object.keys(errors).length) return console.log('errors', errors)

        const request = requestSignUp({ ...newValues, username: newValues.email })
        request.then((response) => {
            dispatch(login(response.data))
            dispatch(setFlash("You're logged in"))
        }).catch((error) => {
            if (error.response && error.response.data) {
                dispatch(setFlash(Object.keys(error.response.data).map((key) => error.response.data[key]).join(',')))
            } else {
                dispatch(setFlash('Failed to create user'))
            }
        })

        return false
    }


    return (
        <Page>
            <Row>
                <Col md={{ span: 6, offset: 3 }} className="text-center">
                    <h2>Sign Up</h2>
                </Col>
            </Row>
            <Row>
                <Form onSubmit={submitForm}>
                    <Col md={{ span: 6, offset: 3 }}>
                        <TextInput
                            name="email"
                            title="Email"
                            onUpdate={handleChange}
                            placeholder="marty@mcfly.com"
                            errors={errors}
                            required={true}
                            inputType='email'
                        />


                        <TextInput
                            name="password"
                            title="Password"
                            onUpdate={handleChange}
                            placeholder="you chicken?"
                            errors={errors}
                            required={true}
                            inputType='password'
                        />

                        <p className='notice'>
                            By signing up you agree to our <Link to='/terms' target='_blank'>Terms &amp; Conditions</Link> and to our <Link to='/privacy' target='_blank'>Privacy Policy</Link>.
                        </p>
                        <hr />

                        <Button variant="primary" type="submit"> Sign Up </Button>

                        <Row className='mt-5'>
                            <h5>Already have an account?</h5>
                            <Link className="btn btn-link-grey" to="/login" role="button">Sign in</Link>
                        </Row>
                    </Col>
                </Form>
            </Row>

        </Page>
    )
}

export default Signup
